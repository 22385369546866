const teams = [
	{
		label: "Enlace",
		value: "enlace",
	},
	{
		label: "Coordinador",
		value: "coordinador",
	},
	{
		label: "Secretaría",
		value: "secretaria",
	},
	{
		label: "Orden y disciplina",
		value: "orden",
	},
	{
		label: "Finanzas",
		value: "finanzas",
	},
	{
		label: "Ninguna",
		value: "ninguna",
	},
];

export default teams;
