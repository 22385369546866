const attendanceTypes = [
	{
		label: "Asistencia",
		value: "attendance",
	},
	{
		label: "Ausencia",
		value: "absence",
	},
	{
		label: "Tardanza",
		value: "late",
	},
	{
		label: "Ausencia notificada",
		value: "notifiedabsence",
	},
	{
		label: "Ausencia justificada",
		value: "excusedabsence",
	},
];

export default attendanceTypes;
