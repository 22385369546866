// Prop Types
import PropTypes from "prop-types";

// React
import { useState } from "react";

// Material
import { Menu,
	MenuItem,
	ListItemIcon,
	ListItemText,
	Divider, } from "@mui/material";

// Routing
import { useHistory } from "react-router-dom";

// Icons
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";

// Firebase
import { logOut } from "assets/plugins/firebase/auth";

// Components
import Alert from "components/Alert";

const GuardianMenu = ({
	anchor, open, onClose 
}) => {
	const [ErrorState, setErrorState] = useState({
		status: false,
		message: "",
	});

	const history = useHistory();

	const handleLogOutClick = async () => {
		try {
			await logOut();
			history.push("/auth/login");
		} catch (error) {
			setErrorState({
				status: true,
				message: error.message,
			});
		}
	};

	return (
		<Menu
			anchorEl={anchor}
			keepMounted
			open={open}
			onClose={onClose}
			anchorOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}>
			<MenuItem onClick={onClose}>
				<ListItemIcon>
					<PersonIcon />
				</ListItemIcon>
				<ListItemText>Perfil</ListItemText>
			</MenuItem>
			<Divider />
			<MenuItem onClick={handleLogOutClick}>
				<ListItemIcon>
					<LogoutIcon />
				</ListItemIcon>
				<ListItemText>Salir</ListItemText>
			</MenuItem>
			<Alert
				open={ErrorState.status}
				severity="error"
				message={ErrorState.message}
				onClose={() =>
					setErrorState({
						status: false,
						message: "",
					})
				}
			/>
		</Menu>
	);
};

GuardianMenu.propTypes = {
	anchor: PropTypes.object,
	open: PropTypes.bool.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default GuardianMenu;
