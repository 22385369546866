// Material
import { Grid } from "@mui/material";

//Routing
import { Route, Switch, Redirect } from "react-router-dom";
import { routes } from "components/Routes";

// Components
import { GuardianNavbar } from "components/Navbars";
import { GuardianFooter } from "components/Footers";

const GuardianLayout = () => {
	const getRoutes = (routes) => {
		const children = routes.find(
			(route) => route.path === "/guardian"
		).children;

		return children.map((route, key) => (
			<Route
				path={"/guardian/" + route.path}
				component={route.element}
				key={key}
			/>
		));
	};

	return (
		<Grid container>
			<Grid item xs={12}>
				<GuardianNavbar />
			</Grid>
			<Grid item xs={12} padding={"3%"} bgcolor="background.neutral">
				<Switch>
					{getRoutes(routes)}
					<Redirect from="*" to="/guardian/confirmands" />
				</Switch>
			</Grid>
			<Grid item xs={12}>
				<GuardianFooter />
			</Grid>
		</Grid>
	);
};

export default GuardianLayout;
