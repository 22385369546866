// React
import { useState, useEffect } from "react";

// Constants
import { activityTypes } from "assets/constants";

// Axios
import { createMass,
	createCatechesis,
	createMeeting, } from "assets/plugins/axios";

// Date-fns
import { format } from "date-fns";

// User
import useUser from "assets/hooks/useUser";

// Routing
import { useHistory } from "react-router-dom";

// Material
import { Button,
	Grid,
	Typography,
	Paper,
	TextField,
	Checkbox,
	Autocomplete,
	FormGroup,
	FormControlLabel, } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";

// Components
import Alert from "components/Alert";
import ConfirmDialog from "components/ConfirmDialog";

// Functions
import { getValue } from "assets/functions";

// Log
import { logger } from "assets/plugins/log";

const AddActivities = () => {
	const history = useHistory();

	const { currentUser } = useUser();

	useEffect(()=>{
		logger("[Add Activities] page loaded.",{
			payload: { currentUser },
			source: "/admin/activities/add"
		});
	},[]);

	const [ErrorState, setErrorState] = useState({
		status: false,
		message: "",
	});
	const [ConfirmDialogState, setConfirmDialogState] = useState({
		open: false,
		loading: false,
		title: "",
		description: "",
		confirmText: "",
		cancelText: "",
		onConfirm: () => {},
		confirmColor: "primary",
	});
	const [ActivityDateState, setActivityDateState] = useState(new Date());

	const getActivityOptions = () => {
		// TODO: Are we using this constraint?
		const userCanAddCatechistActivity =
			currentUser.claims.permissions.includes("add_meeting");
		const filteredActivities = activityTypes.filter(
			(activity) => activity.value !== "meeting"
		);

		return userCanAddCatechistActivity ? activityTypes : filteredActivities;
	};

	const handleCancelClick = () => {
		setConfirmDialogState({
			open: true,
			loading: false,
			title: "Cancelar creación de actividad",
			description:
				"Si aceptas, borrarás la actividad y la información escrita en este formulario",
			confirmText: "ACEPTAR",
			cancelText: "CANCELAR",
			onConfirm: handleDialogCancel,
			confirmColor: "error",
		});
	};

	const handleSaveClick = (event) => {
		const activity_type = getValue(
			activityTypes,
			event.target.activity_type.value
		);

		event.preventDefault();

		const formData = {
			activity_type,
			date: event.target.date.value,
			comments: event.target.comments.value,
			has_attendance: event.target.has_attendance.checked,
		};

		setConfirmDialogState({
			open: true,
			loading: false,
			title: "Confirmar creación de actividad",
			description:
				"Si aceptas, agregarás la actividad y la información escrita en este formulario",
			confirmText: "AGREGAR",
			cancelText: "CANCELAR",
			onConfirm: () => handleDialogConfirm(formData),
		});
	};

	const handleDialogClose = () => {
		setConfirmDialogState({
			open: false,
			loading: false,
			title: "",
			description: "",
			confirmText: "",
			cancelText: "",
			onConfirm: () => {},
		});
	};

	const handleDialogCancel = () => {
		handleDialogClose();
		history.push("/admin/activities");
	};

	const handleDialogConfirm = async (formData) => {
		setConfirmDialogState({
			...ConfirmDialogState,
			loading: true,
		});
		try {
			const createActivity = {
				mass: createMass,
				meeting: createMeeting,
				catechesis: createCatechesis,
			};

			const createActivityData = {
				activity: {
					level: currentUser.claims.level,
					date: format(ActivityDateState, "yyyy-MM-dd'T'HH:mm:ssxxx"),
					comments: formData.comments,
					has_attendance: formData.has_attendance,
				},
			};

			await createActivity[formData.activity_type]({
				token: currentUser.token,
				data: createActivityData,
			});

			history.push("/admin/activities");
		} catch (error) {
			const errorMessage = error.request
				? error.request.response
				: "Error.";
			setErrorState({
				status: true,
				message: `${errorMessage} Intente de nuevo.`,
			});
			handleDialogClose();
		}
	};

	return (
		<Grid container spacing={2} component="form" onSubmit={handleSaveClick}>
			{/* Actividad  */}
			<Grid item xs={12}>
				<Paper
					elevation={0}
					style={{
						padding: "3%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Typography variant="h2" paddingBottom="2%">
						Actividad
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
							<Autocomplete
								options={getActivityOptions()}
								autoSelect
								autoHighlight
								fullWidth
								renderInput={(params) => (
									<TextField
										{...params}
										label="Tipo"
										required
										name="activity_type"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={5} md={4} lg={3} xl={3}>
							<DateTimePicker
								ampm={true}
								ampmInClock={true}
								label="Fecha"
								value={ActivityDateState}
								onChange={setActivityDateState}
								renderInput={(params) => (
									<TextField
										{...params}
										required
										fullWidth
										name="date"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={3} xl={3}>
							<TextField
								fullWidth
								label="Comentarios"
								name="comments"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
							<FormGroup>
								<FormControlLabel
									control={<Checkbox name="has_attendance" />}
									label="¿Hay asistencia?"
								/>
							</FormGroup>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid
				item
				xs={12}
				textAlign="end"
				style={{ marginBottom: "50vh", }}>
				<Button
					variant="outlined"
					color="error"
					style={{ margin: "1rem", }}
					onClick={handleCancelClick}>
					CANCELAR
				</Button>
				<Button
					variant="contained"
					style={{ margin: "1rem", }}
					type="submit">
					CREAR
				</Button>
			</Grid>
			<ConfirmDialog
				open={ConfirmDialogState.open}
				loading={ConfirmDialogState.loading}
				title={ConfirmDialogState.title}
				description={ConfirmDialogState.description}
				onConfirm={ConfirmDialogState.onConfirm}
				onClose={() =>
					setConfirmDialogState({
						open: false,
						loading: false,
					})
				}
				confirmText={ConfirmDialogState.confirmText}
				cancelText={ConfirmDialogState.cancelText}
				confirmColor={ConfirmDialogState.confirmColor}
			/>
			<Alert
				open={ErrorState.status}
				severity="error"
				message={ErrorState.message}
				onClose={() =>
					setErrorState({
						status: false,
						message: "",
					})
				}
			/>
		</Grid>
	);
};

export default AddActivities;
