//React
import { useState, useEffect } from "react";

// Material
import { Paper, Grid, Typography, Button } from "@mui/material";

//Routing
import { useHistory } from "react-router-dom";

//Components
import ConfirmandsTable from "components/ConfirmandsTable";
import Alert from "components/Alert";
import Error from "components/Error";
import Loading from "components/Loading";

// Axios
import { confirmands as confirmandsQuery } from "assets/plugins/axios";

//User
import useUser from "assets/hooks/useUser";

// Log
import { logger } from "assets/plugins/log";

// lodash
import { merge } from "lodash";

// flat
import { flatten } from "flat";

const AdminConfirmands = () => {

	const initialGridConfigState = {
		columns: {
			columnVisibilityModel: {
				id: false,
				"user.first_name": true,
				"user.second_name": false,
				"user.last_name": true,
				"user.second_last_name": false,
				"legal_id": false,
				"school": false,
				"user.sex": false,
				"tshirt_size": false,
				"confirmation_group.group": false,
				"catechesis_attendance.attendance": false,
				"catechesis_attendance.absence": false,
				"catechesis_attendance.late": false,
				"catechesis_attendance.notifiedabsence": false,
				"catechesis_attendance.excusedabsence": false,
				"mass_attendance.attendance": false,
				"mass_attendance.absence": false,
				"mass_attendance.late": false,
				"mass_attendance.notifiedabsence": false,
				"mass_attendance.excusedabsence": false,
			}, 
		}, 
	};

	const fieldsMap = {
		id: {
			fieldName: "id",
			expand: "",
			isInitial: true
		},
		"user.first_name": {
			fieldName: "user.first_name",
			expand: "user",
			isInitial: true
		},
		"user.last_name": {
			fieldName: "user.last_name",
			expand: "user",
			isInitial: true
		},
		"legal_id": {
			fieldName: "legal_id",
			expand: "",
			isInitial: false
		},
		"school": {
			fieldName: "school",
			expand: "",
			isInitial: false
		},
		"user.sex": {
			fieldName: "user.sex",
			expand: "user",
			isInitial: false
		},
		"tshirt_size": {
			fieldName: "tshirt_size",
			expand: "",
			isInitial: false
		},
		"confirmation_group.group": {
			fieldName: "confirmation_group.group",
			expand: "confirmation_group",
			isInitial: true
		},
		"user.second_name": {
			fieldName: "user.second_name",
			expand: "user",
			isInitial: false
		},
		"user.second_last_name": {	
			fieldName: "user.second_last_name",
			expand: "user",
			isInitial: false
		},
		"catechesis_attendance.attendance":{
			fieldName: "catechesis_attendance.attendance",
			expand: "",
			isInitial: false
		},
		"catechesis_attendance.absence":{
			fieldName: "catechesis_attendance.absence",
			expand: "",
			isInitial: false
		},
		"catechesis_attendance.late":{
			fieldName: "catechesis_attendance.late",
			expand: "",
			isInitial: false
		},
		"catechesis_attendance.notifiedabsence":{
			fieldName: "catechesis_attendance.notifiedabsence",
			expand: "",
			isInitial: false
		},
		"catechesis_attendance.excusedabsence":{
			fieldName: "catechesis_attendance.excusedabsence",
			expand: "",
			isInitial: false
		},
		"mass_attendance.attendance":{
			fieldName: "mass_attendance.attendance",
			expand: "",
			isInitial: false
		},
		"mass_attendance.absence":{
			fieldName: "mass_attendance.absence",
			expand: "",
			isInitial: false
		},
		"mass_attendance.late":{
			fieldName: "mass_attendance.late",
			expand: "",
			isInitial: false
		},
		"mass_attendance.notifiedabsence":{
			fieldName: "mass_attendance.notifiedabsence",
			expand: "",
			isInitial: false
		},
		"mass_attendance.excusedabsence":{
			fieldName: "mass_attendance.excusedabsence",
			expand: "",
			isInitial: false
		},
	};

	const { currentUser } = useUser();
	
	const canSeeOtherGroups = currentUser.claims.permissions.includes("view_all_level_confirmands");
	
	const history = useHistory();

	const [ErrorState, setErrorState] = useState({
		status: false,
		message: "",
	});
	const [LoadingState, setLoadingState] = useState(false);
	const [ConfirmandsState, setConfirmandsState] = useState([]);
	const [GridConfigState, setGridConfigState] = useState(initialGridConfigState);

	const initialFields = [];
	const initialExpand = [];

	for (const field of Object.values(fieldsMap)) {
		if (field.isInitial) {
			initialFields.push(field.fieldName);
			if (!initialExpand.includes(field.expand)) {
				initialExpand.push(field.expand);
			}
		}
	}	

	const params = { 
		fields: initialFields.toString(),
		expand: initialExpand.toString()
	};

	useEffect(() => {
		const getConfirmands = async () => {
			const response = await confirmandsQuery({
				token: currentUser.token,
				params,
			});
			const confirmands = response.data.results.map(rawConfirmand=>{
				const confirmand = {};
				const flattenedConfirmand = flatten(rawConfirmand);

				for (let key in flattenedConfirmand) {
					confirmand[key] = flattenedConfirmand[key];
				}				

				return confirmand;
			});
			setConfirmandsState(confirmands);
		};

		try {
			getConfirmands();

			logger("[Admin Confirmands] page loaded.",{
				payload: { currentUser },
				source: "/admin/confirmands"
			});
			setLoadingState(false);
		} catch (error) {
			setErrorState({
				status: true,
				message: error.message,
			});
			setLoadingState(false);
		}
	}, [currentUser.token, currentUser.claims.permissions]);

	const onColumnVisibilityModelChange = async (newModel) => {
		const changedColumns = [];

		for (const columnName in newModel) {
			if (newModel[columnName] && !Object.prototype.hasOwnProperty.call(ConfirmandsState[0], columnName)) {
				changedColumns.push(columnName);
			}
		}

		if (changedColumns.length > 0) {
			const fields = `id,${changedColumns.toString()}`;
			const expand = Array.from(new Set(changedColumns.map((changedColumn)=>fieldsMap[changedColumn].expand))).toString();

			setLoadingState(true);

			const newConfirmandsQuery = await confirmandsQuery({
				token: currentUser.token,
				params: {
					fields,
					expand
				}
			});

			const newConfirmandsData = newConfirmandsQuery.data.results.map(confirmand=>{
				const newConfirmand = {};
				const flattenedConfirmand = flatten(confirmand);

				for (let key in flattenedConfirmand) {
					newConfirmand[key] = flattenedConfirmand[key];
				}				

				return newConfirmand;
			});

			setConfirmandsState(prevState => merge(prevState, newConfirmandsData));
			setGridConfigState(prevState => ({
				...prevState,
				columns: {
					...prevState.columns,
					columnVisibilityModel: newModel,
				}
			}));
			setLoadingState(false);
		}
	};

	if (LoadingState) return <Loading />;
	if (ErrorState.status) return <Error />;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Paper
					elevation={0}
					style={{ padding: "1%", }}>
					<Typography name="confirmandos" variant="h1">
						Confirmandos
					</Typography>
				</Paper>
			</Grid>
			{currentUser.claims.permissions.includes("add_confirmand") ? (
				<Grid item xs={12} textAlign="end">
					<Button
						name="agregar"
						variant="contained"
						onClick={() => history.push("/admin/confirmands/add")}>
						AGREGAR CONFIRMANDO
					</Button>
				</Grid>
			) : null}
			<Grid item xs={12}>
				<ConfirmandsTable
					rows={ConfirmandsState}
					showGroups={canSeeOtherGroups}
					initialGridConfigState={GridConfigState}
					onColumnVisibilityModelChange={onColumnVisibilityModelChange}
					onCellClick={(evt) =>history.push(`/admin/confirmand/${evt.row.id}`)}
				/>
			</Grid>
			<Alert
				open={ErrorState.status}
				severity="error"
				message={ErrorState.message}
				onClose={() =>
					setErrorState({
						status: false,
						message: "",
					})
				}
			/>
		</Grid>
	);
};

export default AdminConfirmands;
