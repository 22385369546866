// React
import { useState, useEffect } from "react";

// Constants
import { sexs, schools, tshirtSizes, groups } from "assets/constants";

// Axios
import { guardians,
	createGuardian,
	createUser,
	createConfirmand,
	createRelationship,
	deleteUser } from "assets/plugins/axios";

// Date-fns
import { format } from "date-fns";

// User
import useUser from "assets/hooks/useUser";

// Routing
import { useHistory } from "react-router-dom";

// Material
import { Grid,
	Typography,
	Paper,
	TextField,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Button,
	Autocomplete, } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

// Components
import Alert from "components/Alert";
import ConfirmDialog from "components/ConfirmDialog";
import Loading from "components/Loading";

// Functions
import { getValue, getLabel } from "assets/functions";

// Log
import { logger } from "assets/plugins/log";

const AddConfirmands = () => {
	const history = useHistory();

	const { currentUser } = useUser();

	const defaultBiologicalGuardian = {
		guardian_id: "",
		user_id: "",
		first_name: "",
		second_name: "",
		last_name: "",
		second_last_name: "",
		sex: "",
		email: "",
		cellphone: "",
	};

	const [LoadingState, setLoadingState] = useState(true);
	const [ErrorState, setErrorState] = useState({
		status: false,
		message: "",
	});
	const [ConfirmDialogState, setConfirmDialogState] = useState({
		open: false,
		loading: false,
		title: "",
		description: "",
		confirmText: "",
		cancelText: "",
		onConfirm: () => {},
		confirmColor: "primary",
	});
	const [BirthdateState, setBirthdateState] = useState(new Date());
	const [GuardiansState, setGuardiansState] = useState([]);
	const [GuardianFoundState, setGuardianFoundState] = useState({
		mother: false,
		father: false,
	});
	const [GuardianState, setGuardianState] = useState({
		mother: defaultBiologicalGuardian,
		father: defaultBiologicalGuardian,
	});
	const [SearchGuardianState, setSearchGuardianState] = useState({
		mother: false,
		father: false,
	});

	useEffect(()=>{
		logger("[Add Confirmands] page loaded.",{
			payload: { currentUser },
			source: "/admin/confirmands/add"
		});
	},[]);

	useEffect(() => {
		const getGuardians = async () => {
			const { data } = await guardians({ token: currentUser.token, });
			const guardiansList = data.results.map((guardian) => ({
				id: guardian.id,
				user_id: guardian.user.id,
				first_name: guardian.user.first_name,
				second_name: guardian.user.second_name,
				last_name: guardian.user.last_name,
				second_last_name: guardian.user.second_last_name,
				cellphone: guardian.user.cellphone,
				email: guardian.user.email,
				sex: guardian.user.sex,
			}));
			setGuardiansState(guardiansList);
		};

		try {
			getGuardians();
		} catch (error) {
			setErrorState({
				status: true,
				message: error.message,
			});
		} finally {
			setLoadingState(false);
		}
	}, [currentUser.token]);

	const createAndLinkGuardian = async ({ guardian }) => {
		setConfirmDialogState({
			...ConfirmDialogState,
			loading: true,
		});
		try {
			const newGuardianData = {
				user: {
					uid: "NOT REGISTERED IN FIREBASE",
					first_name: guardian.first_name,
					second_name: guardian.second_name,
					last_name: guardian.last_name,
					second_last_name: guardian.second_last_name,
					email: guardian.email,
					cellphone: guardian.cellphone,
					sex: guardian.sex,
				},
			};
			const backendResponse = await createGuardian({
				token: currentUser.token,
				data: newGuardianData,
			});

			try {
				const newRelationshipData = {
					confirmand: guardian.confirmand_id,
					guardian: backendResponse.data.id,
					relationship: guardian.relationship,
				};

				return createRelationship({
					token: currentUser.token,
					data: newRelationshipData,
				});
			} catch (error) {
				logger("Error on creating new guardian.", {
					source: "/admin/confirmands/add", 
					level: "error", 
					payload: {
						error, currentUser 
					},
				});
				throw error;
			}
		} catch (error) {
			logger("Error on creating new guardian.", {
				source: "/admin/confirmands/add", 
				level: "error", 
				payload: {
					error, currentUser 
				},
			});
			throw error;
		}
	};

	const linkGuardian = ({ guardian }) => {
		try {
			const newRelationshipData = {
				confirmand: guardian.confirmand_id,
				guardian: guardian.id,
				relationship: guardian.relationship,
			};

			return createRelationship({
				token: currentUser.token,
				data: newRelationshipData,
			});
		} catch (error) {
			logger("Error on creating new guardian.", {
				source: "/admin/confirmands/add", 
				level: "error", 
				payload: { error },
			});
			throw error;
		}
	};

	const handleCancelClick = () => {
		setConfirmDialogState({
			open: true,
			loading: false,
			title: "Cancelar creación de confirmando",
			description:
				"Si aceptas, borrarás al usuario y la información escrita en este formulario",
			confirmText: "ACEPTAR",
			cancelText: "CANCELAR",
			onConfirm: handleDialogCancel,
			confirmColor: "error",
		});
	};

	const handleSaveClick = (event) => {
		event.preventDefault();

		const mother = GuardianFoundState.mother
			? {
				...GuardianState.mother,
				relationship: "mother",
			}
			: {
				first_name: event.target.mother_first_name.value,
				second_name: event.target.mother_second_name.value,
				last_name: event.target.mother_last_name.value,
				second_last_name:
						event.target.mother_second_last_name.value,
				cellphone: event.target.mother_cellphone.value,
				sex: getValue(sexs, event.target.sex.value),
				email: event.target.mother_email.value,
				relationship: "mother",
			};

		const father = GuardianFoundState.father
			? {
				...GuardianState.father,
				relationship: "father",
			}
			: {
				first_name: event.target.father_first_name.value,
				second_name: event.target.father_second_name.value,
				last_name: event.target.father_last_name.value,
				second_last_name:
						event.target.father_second_last_name.value,
				cellphone: event.target.father_cellphone.value,
				sex: getValue(sexs, event.target.sex.value),
				email: event.target.father_email.value,
				relationship: "father",
			};

		const formData = {
			first_name: event.target.first_name.value,
			second_name: event.target.second_name.value,
			last_name: event.target.last_name.value,
			second_last_name: event.target.second_last_name.value,
			email: event.target.email.value,
			cellphone: event.target.cellphone.value,
			telephone: event.target.telephone.value,
			address: event.target.address.value,
			birthdate: event.target.birthdate.value,
			school: event.target.school.value,
			tshirt_size: event.target.tshirt_size.value,
			sex: event.target.sex.value,
			legal_id: event.target.legal_id.value,
			is_fee_paid: event.target.is_fee_paid.checked,
			is_first_communion_done:
				event.target.is_first_communion_done.checked,
			allergies: event.target.allergies.value,
			medications: event.target.medications.value,
			conditions: event.target.conditions.value,
			comments: event.target.comments.value,
			mother: mother,
			father: father,
		};

		setConfirmDialogState({
			open: true,
			loading: false,
			title: "Confirmar creación de confirmando",
			description:
				"Si aceptas, agregarás al usuario y la información escrita en este formulario",
			confirmText: "AGREGAR",
			cancelText: "CANCELAR",
			onConfirm: () => handleDialogConfirm(formData),
		});
	};

	const handleDialogClose = () => {
		setConfirmDialogState({
			open: false,
			loading: false,
			title: "",
			description: "",
			confirmText: "",
			cancelText: "",
			onConfirm: () => {},
		});
	};

	const handleDialogCancel = () => {
		handleDialogClose();
		history.push("/admin/confirmands");
	};

	const handleDialogConfirm = async (formData) => {
		setConfirmDialogState({
			...ConfirmDialogState,
			loading: true,
		});

		try {
			const userResponse = await createUser({
				token: currentUser.token,
				data: {
					uid: "NOT REGISTERED IN FIREBASE",
					first_name: formData.first_name,
					second_name: formData.second_name,
					last_name: formData.last_name,
					second_last_name: formData.second_last_name,
					email: formData.email,
					cellphone: formData.cellphone,
					sex: getValue(sexs, formData.sex),
				}
			});

			try {
				const confirmandResponse = await createConfirmand({
					token: currentUser.token,
					data: {
						user: userResponse.data.id,
						telephone: formData.telephone,
						address: formData.address,
						birthdate: format(BirthdateState, "yyyy-MM-dd"),
						school: getValue(schools, formData.school),
						tshirt_size: getValue(
							tshirtSizes,
							formData.tshirt_size
						),
						is_fee_paid: formData.is_fee_paid,
						legal_id: formData.legal_id,
						is_first_communion_done:
								formData.is_first_communion_done,
						allergies: formData.allergies,
						medications: formData.medications,
						conditions: formData.conditions,
						comments: formData.comments,
						confirmation_group: groups[currentUser.claims.level][0].value,
						level: currentUser.claims.level,
						relationships: []
					},
				});

				try {
					GuardianFoundState.mother
						? await linkGuardian({
							guardian: {
								...formData.mother,
								confirmand_id: confirmandResponse.data.id,
							},
						})
						: await createAndLinkGuardian({
							guardian: {
								...formData.mother,
								confirmand_id: confirmandResponse.data.id,
							},
						});
				} catch (error) {
					error.response.data.message = `[Madre biológica] ${error.response.data.message}`;
					throw error;
				}
	
				try {
					GuardianFoundState.father
						? await linkGuardian({
							guardian: {
								...formData.father,
								confirmand_id: confirmandResponse.data.id,
							},
						})
						: await createAndLinkGuardian({
							guardian: {
								...formData.father,
								confirmand_id: confirmandResponse.data.id,
							},
						});
				} catch (error) {
					error.response.data.message = `[Padre biológico] ${error.response.data.message}`;
					throw error;
				}
				history.push("/admin/confirmands");
			} catch (error) {
				await deleteUser({
					token: currentUser.token,
					id: userResponse.data.id,
				});
				error.response.data.message = `[Confirmando] ${error.response.data.message}`;
				throw error;
			}
		} catch (error) {
			const errorMessage = error.response.data.message;
			logger("Error on creating new confirmand.", {
				source: "/admin/confirmands/add", 
				level: "error", 
				payload: { 
					error, 
					currentUser 
				},
			});
			setErrorState({
				status: true,
				message: `[Usuario] ${errorMessage} Intente de nuevo.`,
			});
			handleDialogClose();
		}
	};

	const handleGuardianSearchChange = (
		event,
		value,
		reason,
		guardianRelationship
	) => {
		if (["selectOption", "blur"].includes(reason)) {
			setGuardianState({
				...GuardianState,
				[guardianRelationship]: value,
			});
			setGuardianFoundState({
				...GuardianFoundState,
				[guardianRelationship]: true,
			});
		} else if (reason === "clear") {
			setGuardianFoundState({
				...GuardianFoundState,
				[guardianRelationship]: false,
			});
		}
	};

	const getFullNameLabel = (guardian) => {
		return `${guardian.first_name} ${guardian.second_name} ${guardian.last_name} ${guardian.second_last_name}`
			.trim()
			.replace("  ", " ");
	};

	if (LoadingState) return <Loading />;

	return (
		<Grid container spacing={2} component="form" onSubmit={handleSaveClick}>
			{/* Confirmando  */}
			<Grid item xs={12}>
				<Paper
					elevation={0}
					style={{
						padding: "3%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Typography variant="h2" paddingBottom="2%">
						Confirmando
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
							<TextField
								fullWidth
								label="Nombre"
								required
								name="first_name"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
							<TextField
								fullWidth
								label="Segundo Nombre"
								name="second_name"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
							<TextField
								fullWidth
								label="Apellido"
								required
								name="last_name"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
							<TextField
								fullWidth
								label="Segundo Apellido"
								name="second_last_name"
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={6} lg={3} xl={2}>
							<TextField
								fullWidth
								label="Correo electrónico"
								required
								name="email"
								type="email"
							/>
						</Grid>
						<Grid item xs={12} sm={5} md={6} lg={3} xl={1}>
							<TextField
								fullWidth
								label="Celular"
								name="cellphone"
							/>
						</Grid>
						<Grid item xs={12} sm={5} md={6} lg={3} xl={1}>
							<TextField
								fullWidth
								label="Teléfono fijo"
								name="telephone"
							/>
						</Grid>
						<Grid item xs={12} sm={7} md={6} lg={3} xl={2}>
							<TextField
								fullWidth
								label="Dirección"
								name="address"
							/>
						</Grid>
						<Grid item xs={12} sm={5} md={6} lg={3} xl={2}>
							<DatePicker
								label="Fecha de nacimiento"
								value={BirthdateState}
								onChange={setBirthdateState}
								renderInput={(params) => (
									<TextField
										{...params}
										required
										fullWidth
										name="birthdate"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={7} md={6} lg={3} xl={2}>
							<Autocomplete
								options={schools}
								autoSelect
								autoHighlight
								fullWidth
								renderInput={(params) => (
									<TextField
										{...params}
										label="Colegio"
										required
										name="school"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6} sm={2} md={3} lg={3} xl={2}>
							<Autocomplete
								options={tshirtSizes}
								autoSelect
								autoHighlight
								fullWidth
								renderInput={(params) => (
									<TextField
										{...params}
										label="Talla"
										required
										name="tshirt_size"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={6} sm={2} md={3} lg={3} xl={2}>
							<Autocomplete
								options={sexs}
								autoSelect
								autoHighlight
								fullWidth
								renderInput={(params) => (
									<TextField
										{...params}
										label="Sexo"
										required
										name="sex"
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={5} md={6} lg={3} xl={2}>
							<TextField
								fullWidth
								label="Cédula / Pasaporte"
								name="legal_id"
								required
							/>
						</Grid>
						<Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
							<FormGroup>
								<FormControlLabel
									control={<Checkbox name="is_fee_paid" />}
									label="Canceló cuota"
								/>
							</FormGroup>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			{/* Acudientes */}
			<Grid item xs={12}>
				<Paper
					elevation={0}
					style={{
						padding: "3%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Typography variant="h2" paddingBottom="2%">
						Acudientes
					</Typography>
					<Grid container spacing={3}>
						<Grid item sm={12}>
							<Typography variant="h4" paddingBottom="2%">
								Madre biológica
							</Typography>
							<Grid container spacing={2}>
								<Grid
									item
									xs={12}
									display="flex"
									justifyContent="end">
									{SearchGuardianState.mother ? (
										<Autocomplete
											onChange={(event, value, reason) =>
												handleGuardianSearchChange(
													event,
													value,
													reason,
													"mother"
												)
											}
											autoSelect
											autoHighlight
											isOptionEqualToValue={(
												option,
												value
											) => option.id === value.id}
											options={GuardiansState}
											getOptionLabel={getFullNameLabel}
											type="text"
											fullWidth
											renderInput={(params) => (
												<TextField
													{...params}
													label="Acudiente"
													autoFocus
													name="guardians"
												/>
											)}
										/>
									) : (
										<Button
											color="secondary"
											variant="contained"
											onClick={() =>
												setSearchGuardianState({
													...SearchGuardianState,
													mother: true,
												})
											}
										>
											BUSCAR EN BASE DE DATOS
										</Button>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									{!GuardianFoundState.mother ? (
										<TextField
											fullWidth
											label="Nombre"
											required
											name="mother_first_name"
										/>
									) : (
										<>
											<Typography variant="subtitle1">
												Nombre
											</Typography>
											<Typography variant="body1">
												{
													GuardianState.mother
														.first_name
												}
											</Typography>
										</>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									{!GuardianFoundState.mother ? (
										<TextField
											fullWidth
											label="Segundo nombre"
											name="mother_second_name"
										/>
									) : (
										<>
											<Typography variant="subtitle1">
												Segundo nombre
											</Typography>
											<Typography variant="body1">
												{
													GuardianState.mother
														.second_name
												}
											</Typography>
										</>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									{!GuardianFoundState.mother ? (
										<TextField
											fullWidth
											label="Apellido"
											required
											name="mother_last_name"
										/>
									) : (
										<>
											<Typography variant="subtitle1">
												Apellido
											</Typography>
											<Typography variant="body1">
												{GuardianState.mother.last_name}
											</Typography>
										</>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									{!GuardianFoundState.mother ? (
										<TextField
											fullWidth
											label="Segundo apellido"
											name="mother_second_last_name"
										/>
									) : (
										<>
											<Typography variant="subtitle1">
												Segundo apellido
											</Typography>
											<Typography variant="body1">
												{
													GuardianState.mother
														.second_last_name
												}
											</Typography>
										</>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									{!GuardianFoundState.mother ? (
										<TextField
											fullWidth
											label="Celular"
											required
											name="mother_cellphone"
										/>
									) : (
										<>
											<Typography variant="subtitle1">
												Celular
											</Typography>
											<Typography variant="body1">
												{GuardianState.mother.cellphone}
											</Typography>
										</>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									{!GuardianFoundState.mother ? (
										<Autocomplete
											options={sexs}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Sexo"
													required
													name="mother_sex"
												/>
											)}
											autoSelect
											autoHighlight
											fullWidth
										/>
									) : (
										<>
											<Typography variant="subtitle1">
												Sexo
											</Typography>
											<Typography variant="body1">
												{getLabel(
													sexs,
													GuardianState.mother.sex
												)}
											</Typography>
										</>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									{!GuardianFoundState.mother ? (
										<TextField
											fullWidth
											label="Correo electrónico"
											required
											name="mother_email"
										/>
									) : (
										<>
											<Typography variant="subtitle1">
												Correo electrónico
											</Typography>
											<Typography variant="body1">
												{GuardianState.mother.email}
											</Typography>
										</>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									<Typography variant="subtitle1">
										Relación
									</Typography>
									<Typography variant="body1">
										Madre
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item sm={12}>
							<Typography variant="h4" paddingBottom="2%">
								Padre biológico
							</Typography>
							<Grid container spacing={2}>
								<Grid
									item
									xs={12}
									display="flex"
									justifyContent="end">
									{SearchGuardianState.father ? (
										<Autocomplete
											onChange={(event, value, reason) =>
												handleGuardianSearchChange(
													event,
													value,
													reason,
													"father"
												)
											}
											autoSelect
											autoHighlight
											isOptionEqualToValue={(
												option,
												value
											) => option.id === value.id}
											options={GuardiansState}
											getOptionLabel={getFullNameLabel}
											type="text"
											fullWidth
											renderInput={(params) => (
												<TextField
													{...params}
													label="Acudiente"
													autoFocus
													name="guardians"
												/>
											)}
										/>
									) : (
										<Button
											color="secondary"
											variant="contained"
											onClick={() =>
												setSearchGuardianState({
													...SearchGuardianState,
													father: true,
												})
											}
										>
											BUSCAR EN BASE DE DATOS
										</Button>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									{!GuardianFoundState.father ? (
										<TextField
											fullWidth
											label="Primer nombre"
											required
											name="father_first_name"
										/>
									) : (
										<>
											<Typography variant="subtitle1">
												Primer nombre
											</Typography>
											<Typography variant="body1">
												{
													GuardianState.father
														.first_name
												}
											</Typography>
										</>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									{!GuardianFoundState.father ? (
										<TextField
											fullWidth
											label="Segundo nombre"
											name="father_second_name"
										/>
									) : (
										<>
											<Typography variant="subtitle1">
												Segundo nombre
											</Typography>
											<Typography variant="body1">
												{
													GuardianState.father
														.second_name
												}
											</Typography>
										</>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									{!GuardianFoundState.father ? (
										<TextField
											fullWidth
											label="Primer apellido"
											required
											name="father_last_name"
										/>
									) : (
										<>
											<Typography variant="subtitle1">
												Primer apellido
											</Typography>
											<Typography variant="body1">
												{GuardianState.father.last_name}
											</Typography>
										</>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									{!GuardianFoundState.father ? (
										<TextField
											fullWidth
											label="Segundo apellido"
											name="father_second_last_name"
										/>
									) : (
										<>
											<Typography variant="subtitle1">
												Segundo apellido
											</Typography>
											<Typography variant="body1">
												{
													GuardianState.father
														.second_last_name
												}
											</Typography>
										</>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									{!GuardianFoundState.father ? (
										<TextField
											fullWidth
											label="Celular"
											required
											name="father_cellphone"
										/>
									) : (
										<>
											<Typography variant="subtitle1">
												Celular
											</Typography>
											<Typography variant="body1">
												{GuardianState.father.cellphone}
											</Typography>
										</>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									{!GuardianFoundState.father ? (
										<Autocomplete
											options={sexs}
											renderInput={(params) => (
												<TextField
													{...params}
													label="Sexo"
													required
													name="father_sex"
												/>
											)}
											autoSelect
											autoHighlight
											fullWidth
										/>
									) : (
										<>
											<Typography variant="subtitle1">
												Sexo
											</Typography>
											<Typography variant="body1">
												{getLabel(
													sexs,
													GuardianState.father.sex
												)}
											</Typography>
										</>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									{!GuardianFoundState.father ? (
										<TextField
											fullWidth
											label="Correo electrónico"
											required
											name="father_email"
										/>
									) : (
										<>
											<Typography variant="subtitle1">
												Correo electrónico
											</Typography>
											<Typography variant="body1">
												{GuardianState.father.email}
											</Typography>
										</>
									)}
								</Grid>
								<Grid item xs={12} sm={6} md={6} lg={3}>
									<Typography variant="subtitle1">
										Relación
									</Typography>
									<Typography variant="body1">
										Padre
									</Typography>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			{/* Vida sacramental */}
			<Grid item xs={12}>
				<Paper
					elevation={0}
					style={{
						padding: "3%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Typography variant="h2" paddingBottom="2%">
						Vida sacramental
					</Typography>
					<Grid container spacing={5}>
						{/* Primera comunión */}
						<Grid item sm={12} container spacing={2}>
							<Grid item xs={12}>
								<Typography variant="h4">
									Primera comunión
								</Typography>
							</Grid>
							<Grid item xs={12}>
								<FormGroup>
									<FormControlLabel
										control={
											<Checkbox name="is_first_communion_done" />
										}
										label="¿Recibió el sacramento?"
									/>
								</FormGroup>
							</Grid>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			{/* Salud */}
			<Grid item xs={12}>
				<Paper
					elevation={0}
					style={{
						padding: "3%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Typography variant="h2" paddingBottom="2%">
						Salud
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6} lg={4} xl={4}>
							<TextField
								fullWidth
								multiline
								minRows={4}
								label="Alergias"
								name="allergies"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={4} xl={4}>
							<TextField
								fullWidth
								multiline
								minRows={4}
								label="Medicamentos"
								name="medications"
							/>
						</Grid>
						<Grid item xs={12} md={6} lg={4} xl={4}>
							<TextField
								fullWidth
								multiline
								minRows={4}
								label="Condiciones"
								name="conditions"
							/>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			{/* Comentarios */}
			<Grid item xs={12}>
				<Paper
					elevation={0}
					style={{
						padding: "3%",
						display: "flex",
						flexDirection: "column",
						alignItems: "center",
					}}>
					<Typography variant="h2" paddingBottom="2%">
						Comentarios
					</Typography>
					<Grid container spacing={3}>
						<Grid item xs={12}>
							<TextField
								fullWidth
								multiline
								minRows={5}
								label="Comentarios"
								name="comments"
							/>
						</Grid>
					</Grid>
				</Paper>
			</Grid>
			<Grid item xs={12} textAlign="end">
				<Button
					variant="outlined"
					color="error"
					style={{ margin: "1rem", }}
					onClick={handleCancelClick}>
					CANCELAR
				</Button>
				<Button
					name="crear"
					variant="contained"
					style={{ margin: "1rem", }}
					type="submit">
					CREAR
				</Button>
			</Grid>
			<ConfirmDialog
				open={ConfirmDialogState.open}
				loading={ConfirmDialogState.loading}
				title={ConfirmDialogState.title}
				description={ConfirmDialogState.description}
				onConfirm={ConfirmDialogState.onConfirm}
				onClose={() =>
					setConfirmDialogState({
						open: false,
						loading: false,
					})
				}
				confirmText={ConfirmDialogState.confirmText}
				cancelText={ConfirmDialogState.cancelText}
				confirmColor={ConfirmDialogState.confirmColor}
			/>
			<Alert
				open={ErrorState.status}
				severity="error"
				message={ErrorState.message}
				onClose={() =>
					setErrorState({
						status: false,
						message: "",
					})
				}
			/>
		</Grid>
	);
};

export default AddConfirmands;
