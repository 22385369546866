//React
import { useEffect, useState, createContext } from "react";

//Prop types
import PropTypes from "prop-types";

//Firebase
import { authState } from "assets/plugins/firebase/auth";

//Context
const UserContext = createContext();

// Axios
import { permissions as permissionsQuery } from "assets/plugins/axios";

// Logger
import { logger } from "assets/plugins/log";

//Export Provider
export const UserProvider = ({ children }) => {
	const [currentUser, setCurrentUser] = useState(null);
	const [pending, setPending] = useState(true);

	useEffect(() => {
		const getIdTokenResult = async (user) => {
			try {
				const token = await user.getIdToken();
				const { data: permissionsData } = await permissionsQuery({
					token: token, params: {
						fields: "id,codename,name", expand: null 
					} 
				});
				const idTokenResult = await user.getIdTokenResult();

				const permissions = permissionsData.results.filter(permission=>idTokenResult.claims.permissions?.includes(permission.id)).map(permission=>permission.codename);

				setCurrentUser({
					...user,
					token,
					claims: {
						...idTokenResult.claims,
						permissions,
					},
				});
				setPending(false);
			} catch (error) {
				logger(error,{
					level: "error", source: "userContext", payload: user 
				});
				setCurrentUser(null);
				setPending(false);
			}
		};

		authState((user) => {
			if (!user) {
				setCurrentUser(null);
				setPending(false);
			} else {
				setPending(true);
				getIdTokenResult(user);
			}
		});
	}, []);

	return (
		<UserContext.Provider
			value={{ 
				currentUser, 
				pending 
			}}>
			{children}
		</UserContext.Provider>
	);
};

UserProvider.propTypes = { children: PropTypes.any, };

export default UserContext;
