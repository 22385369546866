// React
import { useState, useEffect } from "react";

// Current User
import useUser from "assets/hooks/useUser";

// Date fns
import { format, parse } from "date-fns";
import { es } from "date-fns/locale";

// Material
import { Grid, Typography, Paper } from "@mui/material";

// Axios
import { activities, patchAttendance } from "assets/plugins/axios";

// Components
import LateList from "components/LateList";
import CollapseSection from "components/CollapseSection";
import Loading from "components/Loading";
import Alert from "components/Alert";
import Error from "components/Error";

// Lodash
import { chain } from "lodash";

// Log
import { logger } from "assets/plugins/log";

const AdminLate = () => {
	const { currentUser } = useUser();

	const [LoadingState, setLoadingState] = useState(true);
	const [AttendanceState, setAttendanceState] = useState([]);
	const [ErrorState, setErrorState] = useState({
		status: false,
		message: "",
	});
	const [SelectedConfirmandState, setSelectedConfirmandState] =
		useState(null);

	const params = {
		forAttendance: true,
		level: currentUser.claims.level,
		expand: "attendances,attendances.user,attendances.user.confirmand,attendances.user.confirmand.confirmation_group",
		fields: "id,date,attendances,attendances.user.id,attendances.user.first_name,attendances.user.last_name,attendances.attendance,attendances.id,attendances.user.confirmand.id,activity_type,attendances.user.confirmand.confirmation_group.group"
	};

	useEffect(() => {
		const getAttendance = async () => {
			try {
				const { data } = await activities({
					token: currentUser.token,
					params,
				});

				const confirmandAttendance = {};

				data.results
					.filter((act) => !["Reunión", "Misa"].includes(act.activity_type))
					.forEach((activity) => {
						const attendances = activity.attendances.filter(
							(attendance) => attendance.user.confirmand !== null
						);
						confirmandAttendance[activity.id] = {
							activity_type: activity.activity_type,
							date: activity.date,
							attendances: attendances,
						};
					});
				setAttendanceState(confirmandAttendance);
				setLoadingState(false);
			} catch (error) {
				setErrorState({
					status: true,
					message: error.message,
				});
			}
		};
		getAttendance();
		logger("[Admin Late] page loaded.",{
			payload: { currentUser },
			source: "/admin/late"
		});
		// eslint-disable-next-line
	}, []);

	const handleMenuItemClick = async (type, activity_id) => {
		setLoadingState(true);
		try {
			await patchAttendance({
				token: currentUser.token,
				id: SelectedConfirmandState.id,
				data: { attendance: type, },
			});
			setAttendanceState((prevState) => {
				const newState = {
					...prevState,
					[activity_id]: {
						...prevState[activity_id],
						attendances: prevState[activity_id].attendances.map(
							(attendance) => {
								if (
									attendance.id === SelectedConfirmandState.id
								) {
									return {
										...attendance,
										attendance: type,
									};
								}
								return attendance;
							}
						),
					},
				};

				return newState;
			});
			setLoadingState(false);
		} catch (error) {
			setErrorState({
				status: true,
				message: error.message,
			});
			setLoadingState(false);
		}
	};

	if (LoadingState) return <Loading />;
	if (ErrorState.status) return <Error />;

	if (Object.keys(AttendanceState).length === 0) {
		return <Typography variant="h1">No hay asistencia hoy</Typography>;
	}

	return (
		<Grid container spacing={2}>
			{Object.entries(AttendanceState).map(
				([activity_id, attendance]) => (
					<Grid container item xs={12} key={activity_id}>
						<Grid item xs={12}>
							<Paper
								elevation={0}
								style={{ padding: "1%", }}>
								<Typography variant="h1">
									{`Tardanzas a ${
										attendance.activity_type
									} del ${format(
										parse(
											attendance.date,
											"yyyy-MM-dd'T'HH:mm:ssxxx",
											new Date()
										),
										"dd 'de' MMMM",
										{ locale: es, }
									)}`}
								</Typography>
							</Paper>
						</Grid>
						<Grid container item xs={12}>
							{chain(attendance.attendances)
								.groupBy(
									"user.confirmand.confirmation_group.group"
								)
								.map((attendances, group) => {
									return {
										group: group,
										attendances: attendances,
									};
								})
								.value()
								.map(({
									group, attendances 
								}) => (
									<Grid
										item
										xs={12}
										key={group}
										component={Paper}
										margin={"1% 0"}
										padding={"1%"}
										elevation={0}>
										<CollapseSection
											defaultExpanded={false}
											title={`Grupo ${group}`}>
											<LateList
												onMenuClick={
													setSelectedConfirmandState
												}
												onMenuItemClick={(type) =>
													handleMenuItemClick(
														type,
														activity_id
													)
												}
												attendances={attendances}
											/>
										</CollapseSection>
									</Grid>
								))}
						</Grid>
					</Grid>
				)
			)}
			<Alert
				open={ErrorState.status}
				severity="error"
				message={ErrorState.message}
				onClose={() =>
					setErrorState({
						status: false,
						message: "",
					})
				}
			/>
		</Grid>
	);
};

export default AdminLate;
