// Prop Types
import PropTypes from "prop-types";

// React
import { useState, useRef } from "react";

// Functions
import { getCroppedImage } from "assets/functions";

// Material
import { Grid, Button, Dialog, DialogContent } from "@mui/material";
import { LoadingButton } from "@mui/lab";

import CloudDoneIcon from "@mui/icons-material/CloudDone";
import Edit from "@mui/icons-material/ModeEditOutlineOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";

// React Crop
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

// Components
import Alert from "components/Alert";

const DisplayEditImage = ({
	saveImage, defaultImageURL, disabled 
}) => {
	const handleImageChange = (event) => {
		const eventTargetFiles = event.target.files;
		if (eventTargetFiles && eventTargetFiles.length > 0) {
			const reader = new FileReader();
			reader.addEventListener("load", () =>
				setImageState(reader.result.toString() || "")
			);
			reader.readAsDataURL(eventTargetFiles[0]);
			setImageCropDialogOpenState(true);
		}
	};

	const centerAspectCrop = (mediaWidth, mediaHeight, aspect) => {
		return centerCrop(
			makeAspectCrop(
				{
					unit: "%",
					width: 90,
				},
				aspect,
				mediaWidth,
				mediaHeight
			),
			mediaWidth,
			mediaHeight
		);
	};

	const handleImageLoad = (event) => {
		const {
			width, height 
		} = event.currentTarget;
		setCropState(centerAspectCrop(width, height, AspectState));
	};

	const handleImageSave = async () => {
		setSavingImageState(true);
		try {
			const croppedImage = await getCroppedImage(
				imageRef.current,
				CompletedCropState,
				1,
				0
			);
			await saveImage(croppedImage);
			setImageCropDialogOpenState(false);
			setSavingImageState(false);
		} catch (error) {
			setImageCropDialogOpenState(false);
			setSavingImageState(false);
			setErrorState({
				status: true,
				message: `${error} Intente de nuevo.`,
			});
		}
	};

	const handleImageCancel = () => {
		setImageState(defaultImageURL);
		setImageCropDialogOpenState(false);
	};

	const [ErrorState, setErrorState] = useState({
		status: false,
		message: "",
	});
	const [SavingImageState, setSavingImageState] = useState(false);
	const [ImageState, setImageState] = useState(defaultImageURL);
	const [ImageCropDialogOpenState, setImageCropDialogOpenState] =
		useState(false);
	const [CropState, setCropState] = useState();
	const [CompletedCropState, setCompletedCropState] = useState();
	const [AspectState] = useState(1);
	const imageRef = useRef();

	return (
		<Grid container alignItems="center" justifyContent="start" spacing={1}>
			<Grid item xs={12} md={6} lg={3} textAlign="start">
				<Button
					variant="contained"
					color="secondary"
					startIcon={<VisibilityIcon />}
					onClick={() => window.open(defaultImageURL, "_blank")}>
					VER
				</Button>
			</Grid>
			<Grid item xs={12} md={6} lg={3} textAlign="start">
				{!disabled ? (
					<label htmlFor="upload-photo">
						<Button
							component="label"
							variant="contained"
							color="secondary"
							startIcon={<Edit />}>
							EDITAR
							<input
								onChange={handleImageChange}
								style={{ display: "none", }}
								id="upload-photo"
								name="upload-photo"
								type="file"
								accept="image/*"
							/>
						</Button>
					</label>
				) : null}
			</Grid>
			<Grid item xs={12} textAlign="center">
				<Dialog open={ImageCropDialogOpenState} fullWidth>
					<DialogContent>
						<Grid
							container
							alignItems="center"
							justifyContent="center">
							<Grid item xs={12}>
								<ReactCrop
									crop={CropState}
									onChange={(_, percentCrop) =>
										setCropState(percentCrop)
									}
									onComplete={setCompletedCropState}
									aspect={AspectState}>
									<img
										src={ImageState}
										alt="Crop me"
										onLoad={handleImageLoad}
										ref={imageRef}
									/>
								</ReactCrop>
							</Grid>
							<Grid item xs={6} textAlign="end">
								<LoadingButton
									onClick={handleImageSave}
									loading={SavingImageState}
									variant="contained"
									color="secondary"
									startIcon={<CloudDoneIcon />}
									style={{ margin: "1rem", }}>
									GUARDAR
								</LoadingButton>
							</Grid>
							<Grid item xs={6} textAlign="start">
								<Button
									onClick={handleImageCancel}
									variant="outlined"
									color="secondary"
									style={{ margin: "1rem", }}>
									CANCELAR
								</Button>
							</Grid>
						</Grid>
					</DialogContent>
				</Dialog>
			</Grid>
			<Alert
				open={ErrorState.status}
				severity="error"
				message={ErrorState.message}
				onClose={() =>
					setErrorState({
						status: false,
						message: "",
					})
				}
			/>
		</Grid>
	);
};

DisplayEditImage.propTypes = {
	saveImage: PropTypes.func.isRequired,
	defaultImageURL: PropTypes.string.isRequired,
	disabled: PropTypes.bool,
};

export default DisplayEditImage;
