import { auth } from "./index";
import { signInWithEmailAndPassword,
	onAuthStateChanged,
	signOut,
	sendPasswordResetEmail,
	connectAuthEmulator,
	GoogleAuthProvider,
	signInWithPopup, } from "firebase/auth";

if (process.env.REACT_APP_ENV === "development") {
	connectAuthEmulator(auth, "http://localhost:9099");
}


if (process.env.REACT_APP_ENV === "cypress") {
	connectAuthEmulator(auth, "http://firebase:9099");
}

const googleProvider = new GoogleAuthProvider();

export const emailLogin = (email, password) => {
	return signInWithEmailAndPassword(auth, email, password);
};

export const googleLogin = () => {
	return signInWithPopup(auth, googleProvider);
};

export const authState = (callback) => {
	return onAuthStateChanged(auth, callback);
};

export const logOut = () => {
	return signOut(auth);
};

export const passwordReset = (email) => {
	return sendPasswordResetEmail(auth, email);
};
