// Prop Types
import PropTypes from "prop-types";

// Material
import { Snackbar, Alert as MuiAlert } from "@mui/material";

const Alert = ({
	open, severity, message, onClose 
}) => {
	return (
		<Snackbar open={open} autoHideDuration={6000000} onClose={onClose}>
			<MuiAlert onClose={onClose} severity={severity}>
				{message}
			</MuiAlert>
		</Snackbar>
	);
};

Alert.propTypes = {
	open: PropTypes.bool.isRequired,
	severity: PropTypes.string.isRequired,
	message: PropTypes.string.isRequired,
	onClose: PropTypes.func.isRequired,
};

export default Alert;
