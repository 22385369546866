import Home from "pages/Home";

// Layouts
import AdminLayout from "layouts/AdminLayout";
import ConfirmandLayout from "layouts/ConfirmandLayout";
import GuardianLayout from "layouts/GuardianLayout";

// Admin routes
import AddActivities from "pages/admin/AddActivities";
import AdminActivities from "pages/admin/AdminActivities";
import AdminActivity from "pages/admin/AdminActivity";
import AdminAttendances from "pages/admin/AdminAttendances";
import AdminAttendance from "pages/admin/AdminAttendance";
import AdminCatechistAttendances from "pages/admin/AdminCatechistAttendances";
import AdminCatechistAttendance from "pages/admin/AdminCatechistAttendance";
import AdminDashboard from "pages/admin/AdminDashboard";
import AddCatechists from "pages/admin/AddCatechists";
import AdminCatechist from "pages/admin/AdminCatechist";
import AdminCatechists from "pages/admin/AdminCatechists";
import AdminConfirmands from "pages/admin/AdminConfirmands";
import AdminConfirmand from "pages/admin/AdminConfirmand";
import AddConfirmands from "pages/admin/AddConfirmands";
import AdminLate from "pages/admin/AdminLate";
import AdminProfile from "pages/admin/AdminProfile";

// Confirmand routes
import ConfirmandDashboard from "pages/confirmand/ConfirmandDashboard";
import ConfirmandProfile from "pages/confirmand/ConfirmandProfile";

// Guardian routes
import GuardianConfirmands from "pages/guardian/GuardianConfirmands";
import GuardianConfirmand from "pages/guardian/GuardianConfirmand";

// Auth routes
import Login from "pages/auth/Login";
import Logout from "pages/auth/Logout";
import AuthLayout from "layouts/AuthLayout";

// Icons
import DashboardIcon from "@mui/icons-material/Dashboard";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import PasswordReset from "pages/auth/PasswordReset";
import GroupsIcon from "@mui/icons-material/Groups";
import GroupIcon from "@mui/icons-material/Group";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

const routes = [
	{
		path: "/admin",
		element: AdminLayout,
		permissions: ["view_confirmationgroup"],
		catechist: true,
		children: [
			{
				path: "dashboard",
				element: AdminDashboard,
				permissions: ["view_confirmationgroup"],
				name: "Dashboard",
				drawer: true,
				icon: <DashboardIcon />,
			},
			{
				path: "confirmands/add",
				element: AddConfirmands,
				permissions: ["add_confirmand", "add_user", "add_relationship", "add_guardian", "view_guardian"],
				name: "Agregar confirmando",
				drawer: false,
			},
			{
				path: "confirmands",
				element: AdminConfirmands,
				permissions: ["view_confirmand"],
				name: "Confirmandos",
				drawer: true,
				icon: <GroupsIcon />,
			},
			{
				path: "confirmand/:id",
				element: AdminConfirmand,
				permissions: ["view_confirmand", "view_guardian"],
				name: "Confirmando",
				drawer: false,
			},
			{
				path: "catechists/add",
				element: AddCatechists,
				permissions: ["add_catechist", "view_group"],
				name: "Catequistas",
				drawer: false,
				icon: <GroupIcon />,
			},
			{
				path: "catechists",
				element: AdminCatechists,
				permissions: ["view_catechist"],
				name: "Catequistas",
				drawer: true,
				icon: <GroupIcon />,
			},
			{
				path: "catechist/:id",
				element: AdminCatechist,
				permissions: ["view_catechist", "view_group"],
				name: "Catequista",
				drawer: false,
			},
			{
				path: "attendances",
				element: AdminAttendances,
				permissions: ["view_attendance", "view_activity"],
				name: "Asistencias",
				drawer: true,
				icon: <EmojiPeopleIcon />,
			},
			{
				path: "attendance/:id",
				element: AdminAttendance,
				permissions: ["view_attendance", "view_activity"],
				name: "Asistencia",
				drawer: false,
				icon: <EmojiPeopleIcon />,
			},
			{
				path: "catechistAttendances",
				element: AdminCatechistAttendances,
				permissions: ["change_catechist_attendance", "view_activity"],
				name: "Asistencias de catequistas",
				drawer: true,
				icon: <EmojiPeopleIcon />,
			},
			{
				path: "catechistAttendance/:id",
				element: AdminCatechistAttendance,
				permissions: ["change_catechist_attendance", "view_activity"],
				name: "Asistencia de catequistas",
				drawer: false,
				icon: <EmojiPeopleIcon />,
			},
			{
				path: "late",
				element: AdminLate,
				permissions: ["change_late_attendance", "view_activity"],
				name: "Tardanzas",
				drawer: true,
				icon: <AssignmentLateIcon />,
			},
			{
				path: "activities/add",
				element: AddActivities,
				permissions: ["add_activity"],
				name: "Agregar actividad",
				drawer: false,
			},
			{
				path: "activities",
				element: AdminActivities,
				permissions: ["view_activity", "view_mass", "view_meeting", "view_catechesis"],
				name: "Actividades",
				drawer: true,
				icon: <AssignmentIndIcon />,
			},
			{
				path: "activity/:id",
				element: AdminActivity,
				permissions: ["view_activity"],
				name: "Actividad",
				drawer: false,
			},
			{
				path: "profile",
				element: AdminProfile,
				permissions: ["view_catechist"],
				name: "Perfil",
				drawer: false,
			},
		],
	},
	{
		path: "/auth",
		element: AuthLayout,
		permissions: [],
		children: [
			{
				path: "login",
				element: Login,
				name: "Inicio de sesión",
				drawer: false,
			},
			{
				path: "logout",
				element: Logout,
				name: "Cerrar sesión",
				drawer: false,
			},
			{
				path: "resetpassword",
				element: PasswordReset,
				name: "Reestablecer contraseña",
				drawer: false,
			},
		],
	},
	{
		path: "/confirmand",
		element: ConfirmandLayout,
		permissions: ["view_confirmand"],
		catechist: false,
		children: [
			{
				path: "dashboard",
				element: ConfirmandDashboard,
				permissions: ["view_confirmand"],
				name: "Dashboard",
				drawer: true,
				icon: <DashboardIcon />,
			},
			{
				path: "profile",
				element: ConfirmandProfile,
				permissions: ["view_confirmand"],
				name: "Perfil",
				drawer: false,
			},
		],
	},
	{
		path: "/guardian",
		element: GuardianLayout,
		permissions: ["view_confirmand"],
		catechist: false,
		children: [
			{
				path: "confirmands",
				element: GuardianConfirmands,
				permissions: ["view_confirmand"],
				name: "Acudidos",
				drawer: true,
				icon: <GroupsIcon />,
			},
			{
				path: "confirmand/:id",
				element: GuardianConfirmand,
				permissions: ["view_confirmand"],
				name: "Acudido",
				drawer: false,
			},
		],
	
	},
	{
		path: "/",
		element: Home,
		permissions: [],
	},
	// {path: "*", element: <Redirect to="/404"/>}
];

export default routes;
