// React
import { render } from "react-dom";
import { StrictMode } from "react";

// Material Lab
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { es } from "date-fns/locale";

// Router
import { BrowserRouter, Switch } from "react-router-dom";
import { CustomRoute, routes } from "components/Routes";

// Theme
import theme from "./theme";
import { CssBaseline } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";

// User
import { UserProvider } from "assets/plugins/contexts/userContext";

const App = () =>
	<StrictMode>
		<UserProvider>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<LocalizationProvider
					dateAdapter={AdapterDateFns}
					adapterLocale={es}>
					<BrowserRouter>
						<Switch>
							{routes.map((route) => (
								<CustomRoute
									key={route.path}
									path={route.path}
									component={route.element}
									catechist={route.catechist}
									isPublic={route.permissions.length===0}
								/>
							))}
						</Switch>
					</BrowserRouter>
				</LocalizationProvider>
			</ThemeProvider>
		</UserProvider>
	</StrictMode>;

const container = document.getElementById("root");

render(<App/>, container || document.createElement("div"));

export default App;
