// Prop Types	
import PropTypes from "prop-types";

// React
import { useState } from "react";

// Material
import { Grid,
	Typography,
	TextField,
	IconButton,
	Button,
	Autocomplete, } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Edit from "@mui/icons-material/ModeEditOutlineOutlined";

// Components
import Alert from "components/Alert";

// Functions
import { getLabel, getValue } from "assets/functions";

const DisplayAutoComplete = ({
	label,
	defaultValue,
	options,
	name,
	onSave,
	disabled,
	patch,
}) => {
	const [editing, setEditing] = useState(false);
	const [loading, setLoading] = useState(false);
	const [ErrorState, setErrorState] = useState({
		status: false,
		message: "",
	});

	const handleEditClick = () => {
		setEditing(true);
	};

	const dbUpdate = async (value) => {
		try {
			await patch(getValue(options, value));
			onSave({ [name]: getValue(options, value), });
		} catch (error) {
			setErrorState({
				status: true,
				message: `${error.message}. Intente de nuevo.`,
			});
		} finally {
			setEditing(false);
			setLoading(false);
		}
	};

	const handleSaveClick = async (event) => {
		event.preventDefault();
		setLoading(true);
		dbUpdate(event.target.textField.value);
	};

	const handleCancelClick = () => {
		setEditing(false);
	};

	const gettedLabel =
		defaultValue === "" ? "" : getLabel(options, defaultValue);

	if (editing) {
		return (
			<Grid
				container
				spacing={1}
				display="flex"
				alignItems="center"
				component="form"
				onSubmit={handleSaveClick}>
				<Grid item xs={12}>
					<Autocomplete
						options={options.map((option) => option.label)}
						autoSelect
						autoHighlight
						fullWidth
						defaultValue={gettedLabel}
						renderInput={(params) => (
							<TextField
								{...params}
								label={label}
								required
								name="textField"
							/>
						)}
					/>
				</Grid>
				<Grid item container xs={12} spacing={1}>
					<Grid item xs={12} textAlign="end">
						<Button
							disabled={loading}
							onClick={handleCancelClick}
							variant="outlined"
							color="error">
							CANCELAR
						</Button>
					</Grid>
					<Grid item xs={12} textAlign="end">
						<LoadingButton
							loading={loading}
							type="submit"
							variant="contained">
							GUARDAR
						</LoadingButton>
					</Grid>
				</Grid>
				<Alert
					open={ErrorState.status}
					severity="error"
					message={ErrorState.message}
					onClose={() =>
						setErrorState({
							status: false,
							message: "",
						})
					}
				/>
			</Grid>
		);
	}
	return (
		<Grid container spacing={1}>
			<Grid item xs={12} display="flex" alignItems="center">
				<Typography variant="subtitle1">{label}</Typography>
				{!disabled ? (
					<IconButton
						size="small"
						onClick={handleEditClick}
						style={{ margin: "0 1rem", }}>
						<Edit />
					</IconButton>
				) : null}
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body1">{gettedLabel}</Typography>
			</Grid>
			<Alert
				open={ErrorState.status}
				severity="error"
				message={ErrorState.message}
				onClose={() =>
					setErrorState({
						status: false,
						message: "",
					})
				}
			/>
		</Grid>
	);
};

DisplayAutoComplete.propTypes = {
	label: PropTypes.string.isRequired,
	defaultValue: PropTypes.any.isRequired,
	options: PropTypes.array.isRequired,
	name: PropTypes.string.isRequired,
	onSave: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	patch: PropTypes.func.isRequired,
};

export default DisplayAutoComplete;
