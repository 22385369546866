// Prop Types
import PropTypes from "prop-types";

// React
import { useState, useEffect } from "react";

// Material
import { Grid, Typography, TextField, IconButton, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { DatePicker } from "@mui/x-date-pickers";
import Edit from "@mui/icons-material/ModeEditOutlineOutlined";

// Components
import Alert from "components/Alert";

// Date fns
import { format } from "date-fns";

const DisplayDatePicker = ({
	label,
	defaultValue,
	loading,
	name,
	onSave,
	disabled,
	patch,
}) => {
	const [editing, setEditing] = useState(false);
	const [SaveLoading, setSaveLoading] = useState(false);
	const [DateState, setDateState] = useState(new Date());
	const [ErrorState, setErrorState] = useState({
		status: false,
		message: "",
	});

	useEffect(() => {
		if (!loading) {
			setDateState(defaultValue);
		}
	}, [loading, defaultValue]);

	const handleEditClick = () => {
		setEditing(true);
	};

	const handleSaveClick = async () => {
		setSaveLoading(true);
		try {
			await patch(format(DateState, "yyyy-MM-dd"));
			onSave({ [name]: DateState, });
		} catch (error) {
			setErrorState({
				status: true,
				message: `${error.message}. Intente de nuevo.`,
			});
		} finally {
			setEditing(false);
			setSaveLoading(false);
		}
	};

	const handleCancelClick = () => {
		setEditing(false);
	};

	if (editing) {
		return (
			<Grid container spacing={1} display="flex" alignItems="center">
				<Grid item xs={12}>
					<DatePicker
						label={label}
						loading={loading}
						value={DateState}
						disableCloseOnSelect={true}
						onChange={setDateState}
						renderInput={(params) => (
							<TextField {...params} required fullWidth />
						)}
					/>
				</Grid>
				<Grid item container xs={12} spacing={1}>
					<Grid item xs={12} textAlign="end">
						<Button
							disabled={loading}
							onClick={handleCancelClick}
							variant="outlined"
							color="error">
							CANCELAR
						</Button>
					</Grid>
					<Grid item xs={12} textAlign="end">
						<LoadingButton
							loading={SaveLoading}
							onClick={handleSaveClick}
							variant="contained">
							GUARDAR
						</LoadingButton>
					</Grid>
				</Grid>
				<Alert
					open={ErrorState.status}
					severity="error"
					message={ErrorState.message}
					onClose={() =>
						setErrorState({
							status: false,
							message: "",
						})
					}
				/>
			</Grid>
		);
	}
	return (
		<Grid container spacing={1}>
			<Grid item xs={12} display="flex" alignItems="center">
				<Typography variant="subtitle1">{label}</Typography>
				{!disabled ? (
					<IconButton
						size="small"
						onClick={handleEditClick}
						style={{ margin: "0 1rem", }}>
						<Edit />
					</IconButton>
				) : null}
			</Grid>
			<Grid item xs={12}>
				<Typography variant="body1">
					{format(defaultValue, "dd/MM/yyyy")}
				</Typography>
			</Grid>
			<Alert
				open={ErrorState.status}
				severity="error"
				message={ErrorState.message}
				onClose={() =>
					setErrorState({
						status: false,
						message: "",
					})
				}
			/>
		</Grid>
	);
};

DisplayDatePicker.propTypes = {
	label: PropTypes.string.isRequired,
	defaultValue: PropTypes.instanceOf(Date).isRequired,
	loading: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	onSave: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	patch: PropTypes.func.isRequired,
};

export default DisplayDatePicker;
