// Material
import { Box, Typography, IconButton } from "@mui/material";

import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import YoutubeIcon from "@mui/icons-material/YouTube";
import EmailIcon from "@mui/icons-material/Email";
import LanguageIcon from "@mui/icons-material/Language";

const ConfirmandFooter = () => {
	return (
		<Box
			bgcolor="primary.main"
			display="flex"
			alignItems="center"
			padding="0 1%">
			<Typography variant="body2" color="black">
				Confirmación Santa Marta © 2023
			</Typography>
			<IconButton
				href="https://parroquiasantamarta.com/wp/"
				target="_blank">
				<LanguageIcon color="black" />
			</IconButton>
			<IconButton
				href="https://www.instagram.com/parroquiastamarta/"
				target="_blank">
				<InstagramIcon color="black" />
			</IconButton>
			<IconButton
				href="https://www.facebook.com/pasantamarta/"
				target="_blank">
				<FacebookIcon color="black" />
			</IconButton>
			<IconButton
				href="https://m.youtube.com/parroquiasantamartapty"
				target="_blank">
				<YoutubeIcon color="black" />
			</IconButton>
			<IconButton href="mailto:correo@parroquiasantamarta.com">
				<EmailIcon color="black" />
			</IconButton>
		</Box>
	);
};

export default ConfirmandFooter;
