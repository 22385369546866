import logtail from "./logtail";

const logger = (message, {
	level = "info", source, payload
}) =>{
	logtail.log(
		message,
		level,
		{
			source,
			...(typeof payload !== "undefined" && { payload }),
		});
	logtail.flush();
};

export { logger };