const schools = [
	{
		label: "Instituto Sun Yat Sen",
		value: "isys",
	},
	{
		label: "Colegio San Agustín",
		value: "csa",
	},
	{
		label: "Instituto Atenea",
		value: "instate",
	},
	{
		label: "Saint Mary School",
		value: "stma",
	},
	{
		label: "San Judas Tadeo",
		value: "sjt",
	},
	{
		label: "Panamerican School",
		value: "panascho",
	},
	{
		label: "Instituto Fermin Naudeau",
		value: "instfernau",
	},
	{
		label: "The Oxford School",
		value: "tos",
	},
	{
		label: "Oxford International School",
		value: "ois",
	},
	{
		label: "King School",
		value: "kingscho",
	},
	{
		label: "Colegio Real De Panamá ",
		value: "real",
	},
	{
		label: "Colegio Javier",
		value: "javier",
	},
	{
		label: "Colegio De La Salle",
		value: "salle",
	},
	{
		label: "Colegio Gillermo Endara Galimani",
		value: "cgeg",
	},
	{
		label: "Instituto Cultural",
		value: "cult",
	},
	{
		label: "Colegio Las Esclavas",
		value: "cle",
	},
	{
		label: "Instituto Panamericano",
		value: "ipa",
	},
	{
		label: "Colegio De Panama",
		value: "cdp",
	},
	{
		label: "Montessori de Panamá",
		value: "montepma",
	},
	{
		label: "Colegio Episcopal de Panama",
		value: "cep",
	},
	{
		label: "Escuela Francopanameña",
		value: "escufran",
	},
	{
		label: "Colegio Isaac Rabin",
		value: "cir",
	},
	{
		label: "Colegio Europeo de Panama",
		value: "coleurpam",
	},
	{
		label: "Boston School International",
		value: "boston",
	},
	{
		label: "Lincoln Academy ",
		value: "lincoln",
	},
	{
		label: "Colegio Internacional de María Inmaculada",
		value: "cidmi",
	},
	{
		label: "Universidad Tecnológica de Panamá",
		value: "utp",
	},
	{
		label: "Universidad Nacional de Panamá",
		value: "up",
	},
	{
		label: "Universidad Santa María La Antigua",
		value: "usma",
	},
	{
		label: "Universidad Interamericana de Panamá",
		value: "uip",
	},
	{
		label: "Universidad de Arte Ganexa",
		value: "uganexa",
	},
	{
		label: "Otro",
		value: "otro",
	},
];

export default schools;
