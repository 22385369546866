const sexs = [
	{
		label: "Masculino",
		value: "M",
	},
	{
		label: "Femenino",
		value: "F",
	},
];

export default sexs;
