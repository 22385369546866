// Prop Types
import PropTypes from "prop-types";

// Material
import { Grid, Typography } from "@mui/material";

const man_sitting = require("assets/images/work.svg").default;

const Error = ({ errorMessage }) => {
	return (
		<Grid container justifyContent="center" textAlign="center">
			<Grid item xs={12}>
				<Typography variant="h1">
					Parece que tenemos un error: {errorMessage}
				</Typography>
			</Grid>
			<Grid item xs={12}>
				<img
					src={man_sitting}
					alt="Logo de la Confirmacion Santa Marta"
					width="700rem"
				/>
			</Grid>
		</Grid>
	);
};

Error.propTypes = { errorMessage: PropTypes.string.isRequired, };

export default Error;
