import { Logtail } from "@logtail/browser";

let logtail = {
	log: (message, level, context) => {
		switch (level) {
		case "debug":
			console.debug(message, context);
			break;
		case "info":
			console.info(message, context);
			break;
		case "warn":
			console.warn(message, context);
			break;
		case "error":
			console.error(message, context);
			break;
		default:
			console.log(`[${level.toUpperCase()}]`, message, context);
			break;
		}
	},
	flush: () => null
};

if (!["development", "cypress"].includes(process.env.REACT_APP_ENV)){
	logtail = new Logtail(process.env.REACT_APP_LOGTAIL_SOURCE_TOKEN);
}

export default logtail;