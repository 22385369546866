//React
import { useState, useEffect } from "react";

// Material
import { Paper, Grid, Typography } from "@mui/material";

//Components
import CatechistAttendancesTable from "components/CatechistAttendancesTable";
import Alert from "components/Alert";
import Error from "components/Error";
import Loading from "components/Loading";

// Axios
import { activities } from "assets/plugins/axios";

//User
import useUser from "assets/hooks/useUser";

// Log
import { logger } from "assets/plugins/log";

const AdminCatechistAttendances = () => {
	const { currentUser } = useUser();

	const [ErrorState, setErrorState] = useState({
		status: false,
		message: "",
	});
	const [LoadingState, setLoadingState] = useState(true);
	const [ActivitiesState, setActivitiesState] = useState([]);

	useEffect(() => {
		const getActivities = async () => {
			const params = { 
				forAttendance: true,
				level: currentUser.claims.level
			};
			const { data } = await activities({
				token: currentUser.token,
				params: params,
			});
			const levelActivities = data.results.map((activity) => ({
				id: activity.id,
				type: activity.activity_type,
				date: activity.date,
				comments: activity.comments,
			}));
			setActivitiesState(levelActivities);
		};

		try {
			getActivities();
			logger("[Admin Catechist Attendances] page loaded.",{
				payload: { currentUser },
				source: "/admin/catechistAttendances"
			});
			setLoadingState(false);
		} catch (error) {
			setErrorState({
				status: true,
				message: error.message,
			});
		}
	}, [currentUser.claims.level, currentUser.claims.permissions, currentUser.token]);

	if (LoadingState) return <Loading />;
	if (ErrorState.status) return <Error />;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Paper
					elevation={0}
					style={{ padding: "1%", }}>
					<Typography variant="h1">Asistencias de catequistas</Typography>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<CatechistAttendancesTable rows={ActivitiesState} />
			</Grid>
			<Alert
				open={ErrorState.status}
				severity="error"
				message={ErrorState.message}
				onClose={() =>
					setErrorState({
						status: false,
						message: "",
					})
				}
			/>
		</Grid>
	);
};

export default AdminCatechistAttendances;
