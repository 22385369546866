// Prop Types
import PropTypes from "prop-types";

// React
import { useState } from "react";

// Material
import { Grid,
	FormGroup,
	FormControlLabel,
	Checkbox,
	Chip,
	IconButton,
	Button, } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import Check from "@mui/icons-material/CheckCircleOutline";
import Cancel from "@mui/icons-material/CancelOutlined";
import Edit from "@mui/icons-material/ModeEditOutlineOutlined";

// Components
import Alert from "components/Alert";

const DisplayCheckField = ({
	label,
	defaultValue,
	name,
	onSave,
	disabled,
	patch,
}) => {
	const [editing, setEditing] = useState(false);
	const [loading, setLoading] = useState(false);
	const [ErrorState, setErrorState] = useState({
		status: false,
		message: "",
	});

	const handleEditClick = () => {
		setEditing(true);
	};

	const handleSaveClick = async (event) => {
		event.preventDefault();
		setLoading(true);

		try {
			await patch(event.target.checkbox.checked);
			onSave({ [name]: event.target.checkbox.checked, });
		} catch (error) {
			setErrorState({
				status: true,
				message: `${error.message}. Intente de nuevo.`,
			});
		} finally {
			setEditing(false);
			setLoading(false);
		}
	};

	const handleCancelClick = () => {
		setEditing(false);
	};

	if (editing) {
		return (
			<Grid
				container
				spacing={1}
				display="flex"
				alignItems="center"
				component="form"
				onSubmit={handleSaveClick}>
				<Grid item xs={12}>
					<FormGroup>
						<FormControlLabel
							control={
								<Checkbox
									defaultChecked={defaultValue}
									name="checkbox"
								/>
							}
							label={label}
						/>
					</FormGroup>
				</Grid>
				<Grid item container xs={12} spacing={1}>
					<Grid item xs={12} textAlign="end">
						<Button
							disabled={loading}
							onClick={handleCancelClick}
							variant="outlined"
							color="error">
							CANCELAR
						</Button>
					</Grid>
					<Grid item xs={12} textAlign="end">
						<LoadingButton
							loading={loading}
							type="submit"
							variant="contained">
							GUARDAR
						</LoadingButton>
					</Grid>
				</Grid>
				<Alert
					open={ErrorState.status}
					severity="error"
					message={ErrorState.message}
					onClose={() =>
						setErrorState({
							status: false,
							message: "",
						})
					}
				/>
			</Grid>
		);
	}
	return (
		<>
			<Chip
				label={label}
				color={defaultValue ? "success" : "error"}
				icon={defaultValue ? <Check /> : <Cancel />}
			/>
			{!disabled ? (
				<IconButton
					size="small"
					onClick={handleEditClick}
					style={{ margin: "0 1rem", }}>
					<Edit />
				</IconButton>
			) : null}
			<Alert
				open={ErrorState.status}
				severity="error"
				message={ErrorState.message}
				onClose={() =>
					setErrorState({
						status: false,
						message: "",
					})
				}
			/>
		</>
	);
};

DisplayCheckField.propTypes = {
	label: PropTypes.string.isRequired,
	defaultValue: PropTypes.bool.isRequired,
	name: PropTypes.string.isRequired,
	onSave: PropTypes.func.isRequired,
	disabled: PropTypes.bool,
	patch: PropTypes.func.isRequired,
};

export default DisplayCheckField;
