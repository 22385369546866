// React
import { useState, useEffect } from "react";

// Material
import { Grid,
	Paper,
	Typography,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	TableContainer, } from "@mui/material";

// Theming
import { useTheme } from "@mui/material/styles";

// Routing
import { useHistory } from "react-router-dom";

// Axios
import { confirmationGroup } from "assets/plugins/axios";

// User
import useUser from "assets/hooks/useUser";

// Charts
import { PieChart, Pie, ResponsiveContainer, Legend, Cell } from "recharts";

// Constants
import { attendanceTypes } from "assets/constants";

// Functions
import { getLabel } from "assets/functions";

// Components
import Alert from "components/Alert";
import Loading from "components/Loading";
import Error from "components/Error";

// Log
import { logger } from "assets/plugins/log";

const AdminDashboard = () => {
	const { currentUser } = useUser();

	const [ErrorState, setErrorState] = useState({
		status: false,
		message: "",
	});
	const [MassAttendanceState, setMassAttendanceState] = useState([]);
	const [CatechesisAttendanceState, setCatechesisAttendanceState] = useState(
		[]
	);
	const [
		ConfirmandsCatechesisAttendanceState,
		setConfirmandsCatechesisAttendanceState,
	] = useState([]);
	const [ConfirmandsMassAttendanceState, setConfirmandsMassAttendanceState] =
		useState([]);
	const [CatechistsMassAttendanceState, setCatechistsMassAttendanceState] =
		useState([]);
	const [
		CatechistsCatechesisAttendanceState,
		setCatechistsCatechesisAttendanceState,
	] = useState([]);
	const [
		CatechistsMeetingAttendanceState,
		setCatechistsMeetingAttendanceState,
	] = useState([]);
	const [LoadingState, setLoadingState] = useState(true);

	const params = {
		fields: "id,confirmands_catechesis_attendance,confirmands_mass_attendance,catechists_catechesis_attendance,catechists_mass_attendance,confirmands,catechists,confirmands.user,confirmands.user.get_full_name,confirmands.id,confirmands.catechesis_attendance,confirmands.mass_attendance,catechists.user,catechists.user.get_full_name,catechists.id,catechists.catechesis_attendance,catechists.mass_attendance,catechists.meeting_attendance,",
		expand: "confirmands,catechists,confirmands.user,catechists.user"
	};

	useEffect(() => {
		const getGroupAttendances = async () => {
			const response = await confirmationGroup({
				token: currentUser.token,
				params,
				group: currentUser.claims.group,
			});
			const catechesisAttendance = Object.entries(
				response.data.confirmands_catechesis_attendance
			).map(([key, value]) => {
				return {
					name: getLabel(attendanceTypes, key),
					value: value,
				};
			});
			const massAttendance = Object.entries(
				response.data.confirmands_mass_attendance
			).map(([key, value]) => {
				return {
					name: getLabel(attendanceTypes, key),
					value: value,
				};
			});

			setCatechesisAttendanceState(
				catechesisAttendance.sort((a, b) => (b.name > a.name ? -1 : 1))
			);
			setMassAttendanceState(
				massAttendance.sort((a, b) => (b.name > a.name ? -1 : 1))
			);

			const confirmandsCatechesisAttendance = [];
			const confirmandsMassAttendance = [];

			response.data.confirmands.forEach((confirmand) => {
				const fullName = confirmand.user.get_full_name;
				const id = confirmand.id;
				confirmandsCatechesisAttendance.push({
					fullName: fullName,
					id: id,
					...confirmand.catechesis_attendance,
				});
				confirmandsMassAttendance.push({
					fullName: fullName,
					id: id,
					...confirmand.mass_attendance,
				});
			});
			setConfirmandsCatechesisAttendanceState(
				confirmandsCatechesisAttendance
			);
			setConfirmandsMassAttendanceState(confirmandsMassAttendance);

			const catechistsCatechesisAttendance = [];
			const catechistsMassAttendance = [];
			const catechistsMeetingAttendance = [];

			response.data.catechists.forEach((catechist) => {
				const fullName = catechist.user.get_full_name;
				const id = catechist.id;
				catechistsCatechesisAttendance.push({
					fullName: fullName,
					id: id,
					...catechist.catechesis_attendance,
				});
				catechistsMassAttendance.push({
					fullName: fullName,
					id: id,
					...catechist.mass_attendance,
				});
				catechistsMeetingAttendance.push({
					fullName: fullName,
					id: id,
					...catechist.meeting_attendance,
				});
			});

			setCatechistsCatechesisAttendanceState(
				catechistsCatechesisAttendance
			);
			setCatechistsMassAttendanceState(catechistsMassAttendance);
			setCatechistsMeetingAttendanceState(catechistsMeetingAttendance);

			setLoadingState(false);
		};
		try {
			getGroupAttendances();
			logger("[Catechist Dashboard] page loaded.",{
				payload: { currentUser },
				source: "/admin/dashboard"
			});
		} catch (error) {
			setErrorState({
				status: true,
				message: error.message,
			});
			logger("[Catechist Dashboard] page did not load.",{
				payload: { error },
				source: "/admin/dashboard",
				level: "error"
			});
			setLoadingState(false);
		}
	}, [currentUser.claims.group, currentUser.token]);

	const theme = useTheme();
	const history = useHistory();

	const handleConfirmandoClick = (confirmand) => {
		history.push(`/admin/confirmand/${confirmand}`);
	};

	const handleCatequistaClick = (catechist) => {
		history.push(`/admin/catechist/${catechist}`);
	};

	if (LoadingState) return <Loading />;
	if (ErrorState.status) return <Error />;

	return (
		<Grid container spacing={2}>
			<Grid item xs={12}>
				<Paper
					elevation={0}
					style={{ padding: "1%", }}>
					<Typography variant="h1">Dashboard</Typography>
				</Paper>
			</Grid>
			<Grid item xs={12} md={6}>
				<Paper
					elevation={0}
					style={{ padding: "3%", }}>
					<Typography variant="h3" paddingBottom="1%">
						Catequesis (Confirmandos){" "}
					</Typography>
					<ResponsiveContainer width="100%" height={300}>
						<PieChart>
							<Legend verticalAlign="bottom" />
							<Pie
								data={CatechesisAttendanceState}
								legendType="line"
								innerRadius={50}
								dataKey="value"
								nameKey="name"
								label>
								<Cell fill={theme.palette.success.dark} />
								<Cell fill={theme.palette.error.dark} />
								<Cell fill={theme.palette.info.dark} />
								<Cell fill={theme.palette.warning.dark} />
								<Cell fill={theme.palette.warning.main} />
							</Pie>
						</PieChart>
					</ResponsiveContainer>
				</Paper>
			</Grid>
			<Grid item xs={12} md={6}>
				<Paper
					elevation={0}
					style={{ padding: "3%", }}>
					<Typography variant="h3" paddingBottom="1%">
						Misas (Confirmandos){" "}
					</Typography>
					<ResponsiveContainer width="100%" height={300}>
						<PieChart>
							<Legend verticalAlign="bottom" />
							<Pie
								data={MassAttendanceState}
								legendType="line"
								innerRadius={50}
								dataKey="value"
								nameKey="name"
								label>
								<Cell fill={theme.palette.success.dark} />
								<Cell fill={theme.palette.error.dark} />
								<Cell fill={theme.palette.info.dark} />
								<Cell fill={theme.palette.warning.dark} />
								<Cell fill={theme.palette.warning.main} />
							</Pie>
						</PieChart>
					</ResponsiveContainer>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper
					elevation={0}
					style={{ padding: "1%", }}>
					<Typography variant="h3" padding="0 0 2%">
						Asistencia grupal a catequesis por confirmando
					</Typography>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Confirmando</TableCell>
									<TableCell>Asistencias</TableCell>
									<TableCell>Ausencias</TableCell>
									<TableCell>Tardanzas</TableCell>
									<TableCell>Ausencias Notificadas</TableCell>
									<TableCell>
										Ausencias Justificadas
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ConfirmandsCatechesisAttendanceState.map(
									(confirmandAttendance) => (
										<TableRow
											key={confirmandAttendance.id}>
											<TableCell
												onClick={() =>
													handleConfirmandoClick(
														confirmandAttendance.id
													)
												}>
												{confirmandAttendance.fullName}
											</TableCell>
											<TableCell>
												{
													confirmandAttendance.attendance
												}
											</TableCell>
											<TableCell>
												{confirmandAttendance.absence}
											</TableCell>
											<TableCell>
												{confirmandAttendance.late}
											</TableCell>
											<TableCell>
												{
													confirmandAttendance.notifiedabsence
												}
											</TableCell>
											<TableCell>
												{
													confirmandAttendance.excusedabsence
												}
											</TableCell>
										</TableRow>
									)
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper
					elevation={0}
					style={{ padding: "1%", }}>
					<Typography variant="h3" padding="0 0 2%">
						Asistencia grupal a misa por confirmando
					</Typography>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Confirmando</TableCell>
									<TableCell>Asistencias</TableCell>
									<TableCell>Ausencias</TableCell>
									<TableCell>Tardanzas</TableCell>
									<TableCell>Ausencias Notificadas</TableCell>
									<TableCell>
										Ausencias Justificadas
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{ConfirmandsMassAttendanceState.map(
									(confirmandAttendance) => (
										<TableRow
											key={confirmandAttendance.id}>
											<TableCell
												onClick={() =>
													handleConfirmandoClick(
														confirmandAttendance.id
													)
												}>
												{confirmandAttendance.fullName}
											</TableCell>
											<TableCell>
												{
													confirmandAttendance.attendance
												}
											</TableCell>
											<TableCell>
												{confirmandAttendance.absence}
											</TableCell>
											<TableCell>
												{confirmandAttendance.late}
											</TableCell>
											<TableCell>
												{
													confirmandAttendance.notifiedabsence
												}
											</TableCell>
											<TableCell>
												{
													confirmandAttendance.excusedabsence
												}
											</TableCell>
										</TableRow>
									)
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper
					elevation={0}
					style={{ padding: "1%", }}>
					<Typography variant="h3" padding="0 0 2%">
						Asistencia grupal a misas por catequista
					</Typography>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Catequista</TableCell>
									<TableCell>Asistencias</TableCell>
									<TableCell>Ausencias</TableCell>
									<TableCell>Tardanzas</TableCell>
									<TableCell>Ausencias Notificadas</TableCell>
									<TableCell>
										Ausencias Justificadas
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{CatechistsMassAttendanceState.map(
									(catechistAttendance) => (
										<TableRow key={catechistAttendance.id}>
											<TableCell
												onClick={() =>
													handleCatequistaClick(
														catechistAttendance.id
													)
												}>
												{catechistAttendance.fullName}
											</TableCell>
											<TableCell>
												{catechistAttendance.attendance}
											</TableCell>
											<TableCell>
												{catechistAttendance.absence}
											</TableCell>
											<TableCell>
												{catechistAttendance.late}
											</TableCell>
											<TableCell>
												{
													catechistAttendance.notifiedabsence
												}
											</TableCell>
											<TableCell>
												{
													catechistAttendance.excusedabsence
												}
											</TableCell>
										</TableRow>
									)
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper
					elevation={0}
					style={{ padding: "1%", }}>
					<Typography variant="h3" padding="0 0 2%">
						Asistencia grupal a catequesis por catequista
					</Typography>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Catequista</TableCell>
									<TableCell>Asistencias</TableCell>
									<TableCell>Ausencias</TableCell>
									<TableCell>Tardanzas</TableCell>
									<TableCell>Ausencias Notificadas</TableCell>
									<TableCell>
										Ausencias Justificadas
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{CatechistsCatechesisAttendanceState.map(
									(catechistAttendance) => (
										<TableRow key={catechistAttendance.id}>
											<TableCell
												onClick={() =>
													handleCatequistaClick(
														catechistAttendance.id
													)
												}>
												{catechistAttendance.fullName}
											</TableCell>
											<TableCell>
												{catechistAttendance.attendance}
											</TableCell>
											<TableCell>
												{catechistAttendance.absence}
											</TableCell>
											<TableCell>
												{catechistAttendance.late}
											</TableCell>
											<TableCell>
												{
													catechistAttendance.notifiedabsence
												}
											</TableCell>
											<TableCell>
												{
													catechistAttendance.excusedabsence
												}
											</TableCell>
										</TableRow>
									)
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Grid>
			<Grid item xs={12}>
				<Paper
					elevation={0}
					style={{ padding: "1%", }}>
					<Typography variant="h3" padding="0 0 2%">
						Asistencia grupal a reuniones por catequistas
					</Typography>
					<TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Catequista</TableCell>
									<TableCell>Asistencias</TableCell>
									<TableCell>Ausencias</TableCell>
									<TableCell>Tardanzas</TableCell>
									<TableCell>Ausencias Notificadas</TableCell>
									<TableCell>
										Ausencias Justificadas
									</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{CatechistsMeetingAttendanceState.map(
									(catechistAttendance) => (
										<TableRow key={catechistAttendance.id}>
											<TableCell
												onClick={() =>
													handleCatequistaClick(
														catechistAttendance.id
													)
												}>
												{catechistAttendance.fullName}
											</TableCell>
											<TableCell>
												{catechistAttendance.attendance}
											</TableCell>
											<TableCell>
												{catechistAttendance.absence}
											</TableCell>
											<TableCell>
												{catechistAttendance.late}
											</TableCell>
											<TableCell>
												{
													catechistAttendance.notifiedabsence
												}
											</TableCell>
											<TableCell>
												{
													catechistAttendance.excusedabsence
												}
											</TableCell>
										</TableRow>
									)
								)}
							</TableBody>
						</Table>
					</TableContainer>
				</Paper>
			</Grid>
			<Alert
				open={ErrorState.status}
				severity="error"
				message={ErrorState.message}
				onClose={() =>
					setErrorState({
						status: false,
						message: "",
					})
				}
			/>
		</Grid>
	);
};

export default AdminDashboard;
