const isCatechesisDay = (userLevel) => {
	if (process.env.REACT_APP_ENV === "development") {
		return true;
	}
	const today = new Date();
	const day = today.getDay();

	const isFridayCatechesisDay = userLevel === 1 && day === 5;
	const isSaturdayCatechesisDay = userLevel === 2 && day === 6;

	if (isFridayCatechesisDay || isSaturdayCatechesisDay) return true;
	return false;
};

export default isCatechesisDay;