const groups = {
	1: [
		{
			label: "0",
			value: 21,
		},
		{
			label: "1",
			value: 1,
		},
		{
			label: "2",
			value: 2,
		},
		{
			label: "3",
			value: 3,
		},
		{
			label: "4",
			value: 4,
		},
		{
			label: "5",
			value: 5,
		},
		{
			label: "6",
			value: 6,
		},
		{
			label: "7",
			value: 7,
		},
		{
			label: "8",
			value: 8,
		},
		{
			label: "9",
			value: 9,
		},
		{
			label: "10",
			value: 10,
		},
	],
	2: [
		{
			label: "0",
			value: 22,
		},
		{
			label: "1",
			value: 11,
		},
		{
			label: "2",
			value: 12,
		},
		{
			label: "3",
			value: 13,
		},
		{
			label: "4",
			value: 14,
		},
		{
			label: "5",
			value: 15,
		},
		{
			label: "6",
			value: 16,
		},
		{
			label: "7",
			value: 17,
		},
		{
			label: "8",
			value: 18,
		},
		{
			label: "9",
			value: 19,
		},
		{
			label: "10",
			value: 20,
		},
	],
};

export default groups;
