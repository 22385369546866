// Prop Types
import PropTypes from "prop-types";

// React
import { useState, useEffect } from "react";

// Material
import { Autocomplete,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	TextField,
	Typography,
	Zoom, } from "@mui/material";

// Components
import Loading from "components/Loading";
import Error from "components/Error";
import Alert from "components/Alert";

// Constants
import { relationships, sexs } from "assets/constants";

// Functions
import { getValue, getLabel } from "assets/functions";

// Axios
import { guardians,
	createRelationship,
	createGuardian, } from "assets/plugins/axios";

// User
import useUser from "assets/hooks/useUser";

// Log
import { logger } from "assets/plugins/log";

const NewGuardianModal = ({
	open,
	onClose,
	confirmand_id,
	currentRelationships,
}) => {
	const [GuardiansState, setGuardiansState] = useState([]);
	const [GuardianFoundState, setGuardianFoundState] = useState(false);
	const [GuardianState, setGuardianState] = useState({
		id: 100,
		first_name: "",
		second_name: "",
		last_name: "",
		second_last_name: "",
		cellphone: "",
		email: "",
		sex: "",
	});
	const [LoadingState, setLoadingState] = useState(true);
	const [ErrorState, setErrorState] = useState({
		status: false,
		message: "",
	});

	const { currentUser } = useUser();

	useEffect(() => {
		const getGuardians = async () => {
			const { data } = await guardians({ token: currentUser.token, });
			const guardiansList = data.results
				.filter(
					(guardian) => !currentRelationships.includes(guardian.id)
				)
				.map((guardian) => ({
					id: guardian.id,
					user_id: guardian.user.id,
					first_name: guardian.user.first_name,
					second_name: guardian.user.second_name,
					last_name: guardian.user.last_name,
					second_last_name: guardian.user.second_last_name,
					cellphone: guardian.user.cellphone,
					email: guardian.user.email,
					sex: guardian.user.sex,
				}));
			setGuardiansState(guardiansList);
		};

		try {
			getGuardians();
		} catch (error) {
			setErrorState({
				status: true,
				message: error.message,
			});
		} finally {
			setLoadingState(false);
		}
	}, [currentUser.token, currentRelationships]);

	const handleClose = (event) => {
		event.preventDefault();
		if (event.target.guardians.value === "") {
			const guardianFormData = {
				first_name: event.target.first_name.value,
				second_name: event.target.second_name.value,
				last_name: event.target.last_name.value,
				second_last_name: event.target.second_last_name.value,
				cellphone: event.target.cellphone.value,
				email: event.target.email.value,
				sex: event.target.sex.value,
			};

			handleCreateGuardianRelationship({
				relationship: getValue(
					relationships,
					event.target.relationship.value
				),
				guardianFormData: guardianFormData,
			});
		} else {
			handleCreateRelationship({
				relationship: getValue(
					relationships,
					event.target.relationship.value
				),
			});
		}
	};

	const handleGuardianSearchChange = (event, value, reason) => {
		if (["selectOption", "blur"].includes(reason)) {
			setGuardianState(value);
			setGuardianFoundState(true);
		} else if (reason === "clear") {
			setGuardianFoundState(false);
		}
	};

	const getFullNameLabel = (guardian) => {
		return `${guardian.first_name} ${guardian.second_name} ${guardian.last_name} ${guardian.second_last_name}`
			.trim()
			.replace("  ", " ");
	};

	const handleCreateRelationship = async ({ relationship }) => {
		const data = {
			confirmand: confirmand_id,
			guardian: GuardianState.id,
			relationship: relationship,
		};

		try {
			const newRelationship = await createRelationship({
				token: currentUser.token,
				data: data,
			});

			const newGuardian = {
				id: GuardianState.id,
				user_id: GuardianState.user_id,
				relationship_id: newRelationship.data.id,
				first_name: GuardianState.first_name,
				second_name: GuardianState.second_name,
				last_name: GuardianState.last_name,
				second_last_name: GuardianState.second_last_name,
				cellphone: GuardianState.cellphone,
				email: GuardianState.email,
				relationship: relationship,
				sex: GuardianState.sex,
			};
			onClose({ newGuardian: newGuardian, });
		} catch (error) {
			setErrorState({
				status: true,
				message: error.message,
			});
		}
	};

	const handleCreateGuardianRelationship = async ({
		relationship,
		guardianFormData,
	}) => {
		try {
			const newGuardianData = {
				user: {
					uid: "NOT REGISTERED IN FIREBASE",
					first_name: guardianFormData.first_name,
					second_name: guardianFormData.second_name,
					last_name: guardianFormData.last_name,
					second_last_name: guardianFormData.second_last_name,
					cellphone: guardianFormData.cellphone,
					email: guardianFormData.email,
					sex: getValue(sexs, guardianFormData.sex),
				},
			};

			const guardianResponse = await createGuardian({
				token: currentUser.token,
				data: newGuardianData,
			});

			try {
				const newRelationshipData = {
					confirmand: confirmand_id,
					guardian: guardianResponse.data.id,
					relationship: relationship,
				};

				const newRelationship = await createRelationship({
					token: currentUser.token,
					data: newRelationshipData,
				});

				const newGuardian = {
					id: guardianResponse.data.id,
					user_id: guardianResponse.data.user.id,
					first_name: guardianResponse.data.user.first_name,
					second_name: guardianResponse.data.user.second_name,
					last_name: guardianResponse.data.user.last_name,
					second_last_name:
							guardianResponse.data.user.second_last_name,
					cellphone: guardianResponse.data.user.cellphone,
					email: guardianResponse.data.user.email,
					relationship: relationship,
					relationship_id: newRelationship.data.id,
					sex: guardianResponse.data.user.sex,
				};

				onClose({ newGuardian: newGuardian, });
			} catch (error) {
				logger("Error on creating new guardian.", {
					source: "/admin/confirmands/add", 
					level: "error", 
					payload: { error },
				});
				throw error;
			}
		} catch (error) {
			setErrorState({
				status: true,
				message: error.message,
			});
		}
	};

	if (LoadingState) return <Loading />;
	if (ErrorState.status) return <Error />;

	return (
		<Dialog
			open={open}
			TransitionComponent={Zoom}
			fullWidth
			maxWidth={"xl"}
			component="form"
			onSubmit={handleClose}>
			<DialogTitle>Nuevo Acudiente</DialogTitle>
			<DialogContent>
				<DialogContentText>
					Recuerda primero buscar al acudiente en la base de datos
					para evitar duplicados.
				</DialogContentText>
				<Grid container spacing={3}>
					<Grid item xs={12}>
						<Autocomplete
							onChange={handleGuardianSearchChange}
							autoSelect
							autoHighlight
							isOptionEqualToValue={(option, value) =>
								option.id === value.id
							}
							options={GuardiansState}
							getOptionLabel={getFullNameLabel}
							type="text"
							fullWidth
							renderInput={(params) => (
								<TextField
									{...params}
									label="Acudiente"
									autoFocus
									name="guardians"
								/>
							)}
						/>
					</Grid>
					<Grid item xs={3}>
						{!GuardianFoundState ? (
							<TextField
								type="text"
								fullWidth
								label="Nombre"
								required
								name="first_name"
							/>
						) : (
							<>
								<Typography variant="subtitle1">
									Nombre
								</Typography>
								<Typography variant="body1">
									{GuardianState.first_name}
								</Typography>
							</>
						)}
					</Grid>
					<Grid item xs={3}>
						{!GuardianFoundState ? (
							<TextField
								type="text"
								fullWidth
								label="Segundo Nombre"
								name="second_name"
							/>
						) : (
							<>
								<Typography variant="subtitle1">
									Segundo Nombre
								</Typography>
								<Typography variant="body1">
									{GuardianState.second_name}
								</Typography>
							</>
						)}
					</Grid>
					<Grid item xs={3}>
						{!GuardianFoundState ? (
							<TextField
								type="text"
								fullWidth
								label="Apellido"
								required
								name="last_name"
							/>
						) : (
							<>
								<Typography variant="subtitle1">
									Apellido
								</Typography>
								<Typography variant="body1">
									{GuardianState.last_name}
								</Typography>
							</>
						)}
					</Grid>
					<Grid item xs={3}>
						{!GuardianFoundState ? (
							<TextField
								type="text"
								fullWidth
								label="Segundo Apellido"
								name="second_last_name"
							/>
						) : (
							<>
								<Typography variant="subtitle1">
									Segundo Apellido
								</Typography>
								<Typography variant="body1">
									{GuardianState.second_last_name}
								</Typography>
							</>
						)}
					</Grid>
					<Grid item xs={3}>
						{!GuardianFoundState ? (
							<TextField
								type="text"
								fullWidth
								label="Celular"
								name="cellphone"
							/>
						) : (
							<>
								<Typography variant="subtitle1">
									Celular
								</Typography>
								<Typography variant="body1">
									{GuardianState.cellphone}
								</Typography>
							</>
						)}
					</Grid>
					<Grid item xs={3}>
						{GuardianFoundState ? (
							<>
								<Typography variant="subtitle1">
									Sexo
								</Typography>
								<Typography variant="body1">
									{getLabel(sexs, GuardianState.sex)}
								</Typography>
							</>
						) : (
							<Autocomplete
								options={sexs}
								autoSelect
								autoHighlight
								fullWidth
								renderInput={(params) => (
									<TextField
										{...params}
										label={"Sexo"}
										required
										name="sex"
									/>
								)}
							/>
						)}
					</Grid>
					<Grid item xs={3}>
						{!GuardianFoundState ? (
							<TextField
								fullWidth
								label="Email"
								required
								name="email"
								type="email"
							/>
						) : (
							<>
								<Typography variant="subtitle1">
									Correo electrónico
								</Typography>
								<Typography variant="body1">
									{GuardianState.email}
								</Typography>
							</>
						)}
					</Grid>
					<Grid item xs={3}>
						<Autocomplete
							options={relationships
								.filter(
									(option) =>
										!["mother", "father"].includes(
											option.value
										)
								)
								.map((option) => option.label)}
							autoSelect
							autoHighlight
							fullWidth
							renderInput={(params) => (
								<TextField
									{...params}
									label={"Relación"}
									required
									name="relationship"
								/>
							)}
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() =>
						onClose({ canceled: true, })
					}>
					CANCELAR
				</Button>
				<Button type="submit">
					{GuardianFoundState ? "ASOCIAR" : "CREAR Y ASOCIAR"}
				</Button>
			</DialogActions>
			<Alert
				open={ErrorState.status}
				severity="error"
				message={ErrorState.message}
				onClose={() =>
					setErrorState({
						status: false,
						message: "",
					})
				}
			/>
		</Dialog>
	);
};

NewGuardianModal.propTypes = {
	onClose: PropTypes.func.isRequired,
	open: PropTypes.bool.isRequired,
	confirmand_id: PropTypes.number.isRequired,
	currentRelationships: PropTypes.array.isRequired,
};

export default NewGuardianModal;