import axios from "axios";

const BASE_FIREBASE_URL = {
	development: "http://localhost:5001/confirmacionsantamarta/us-central1/api",
	production:
		"https://us-central1-confirmacionsantamarta.cloudfunctions.net/api",
	staging:
		"https://us-central1-stagingconfirmacionsantamarta.cloudfunctions.net/api",
};

const BASE_BACKEND_URL = {
	development: "http://localhost:8000/api",
	cypress: "http://backend:8000/api",
	production: "https://api.confism.com/api",
	staging: "https://staging-api.confism.com/api",
};

const firebaseAxios = axios.create({ baseURL: BASE_FIREBASE_URL[process.env.REACT_APP_ENV], });

const backendAxios = axios.create({ baseURL: BASE_BACKEND_URL[process.env.REACT_APP_ENV], });

export const getDisplayPicture = ({
	token, data 
}) => {
	return firebaseAxios.post(
		"/auth/getDisplayPicture",
		{ data: data, },
		{ headers: { Authorization: `Bearer ${token}`, }, }
	);
};

export const updateDisplayPicture = ({
	token, data 
}) => {
	return firebaseAxios.post(
		"/auth/updateDisplayPicture",
		{ data: data, },
		{ headers: { Authorization: `Bearer ${token}`, }, }
	);
};

export const permissionGroups = ({ token }) => {
	return backendAxios.get("groups", { headers: { Authorization: `Bearer ${token}`, }, });
};

export const permissions = ({
	token, params 
}) => {
	return backendAxios.get("permissions", {
		headers: { Authorization: `Bearer ${token}`, },
		params: params, 
	});
};

export const confirmationGroup = ({
	token, params, group 
}) => {
	return backendAxios.get(`confirmation_groups/${group}`, { 
		headers: { Authorization: `Bearer ${token}`, },
		params: params,
	});
};

export const confirmand = ({
	token, params 
}) => {
	return backendAxios.get(`confirmands/${params.id}`, { 
		headers: { Authorization: `Bearer ${token}`, },
		params,
	});
};

export const confirmands = ({
	token, params 
}) => {
	return backendAxios.get("confirmands" , { 
		headers: { Authorization: `Bearer ${token}`, },
		params
	});
};

export const confirmandSelf = ({
	token, params 
}) => {
	return backendAxios.get("confirmands/", { 
		headers: { Authorization: `Bearer ${token}`, }, 
		params
	});
};

export const catechist = ({
	token, params 
}) => {
	return backendAxios.get(`catechists/${params.id}`, { 
		headers: { Authorization: `Bearer ${token}`, },
		params
	});
};

export const catechists = ({
	token, params 
}) => {
	return backendAxios.get("catechists", { 
		headers: { Authorization: `Bearer ${token}`, }, 
		params
	});
};

export const catechistSelf = ({
	token, params 
}) => {
	return backendAxios.get(`catechists/?search=${params.user_uid}`, { 
		headers: { Authorization: `Bearer ${token}`, }, 
		params
	});
};

export const attendances = ({
	token, params 
}) => {
	return backendAxios.get("attendances", { 
		headers: { Authorization: `Bearer ${token}`, }, 
		params
	});
};

export const activities = ({
	token, params 
}) => {
	return backendAxios.get("activities/?ordering=-date", {
		headers: { Authorization: `Bearer ${token}`, },
		params,
	});
};

export const activity = ({
	token, params, id
}) => {
	return backendAxios.get(`activities/${id}`, {
		headers: { Authorization: `Bearer ${token}`, },
		params,
	});
};

export const mass = ({
	token, params 
}) => {
	return backendAxios.get(`masses/?search=${params.id}`, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const catechesis = ({
	token, params 
}) => {
	return backendAxios.get(`catechesis/?search=${params.id}`, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const meeting = ({
	token, params 
}) => {
	return backendAxios.get(`meetings/?search=${params.id}`, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const guardians = ({ token }) => {
	return backendAxios.get("guardians/", { headers: { Authorization: `Bearer ${token}`, }, });
};

export const patchUser = ({
	token, id, data 
}) => {
	return backendAxios.patch(`users/${id}/`, data, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const patchCatechist = ({
	token, id, data 
}) => {
	return backendAxios.patch(`catechists/${id}/`, data, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const patchConfirmand = ({
	token, id, data 
}) => {
	return backendAxios.patch(`confirmands/${id}/`, data, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const patchRelationship = ({
	token, id, data 
}) => {
	return backendAxios.patch(`relationships/${id}/`, data, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const patchActivity = ({
	token, id, data 
}) => {
	return backendAxios.patch(`activities/${id}/`, data, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const patchCatechesis = ({
	token, id, data 
}) => {
	return backendAxios.patch(`catechesis/${id}/`, data, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const patchAttendance = ({
	token, id, data 
}) => {
	return backendAxios.patch(`attendances/${id}/`, data, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const createRelationship = ({
	token, data 
}) => {
	return backendAxios.post("relationships/", data, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const createGuardian = ({
	token, data 
}) => {
	return backendAxios.post("guardians/", data, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const createUser = ({
	token, data, params
}) => {
	return backendAxios.post("users/", 
		data,
		{ 
			headers: { Authorization: `Bearer ${token}`, },
			params,
		}
	);
};

export const createConfirmand = ({
	token, data, params
}) => {
	return backendAxios.post("confirmands/", 
		data,
		{ 
			headers: { Authorization: `Bearer ${token}`, },
			params,
		}
	);
};

export const createCatechist = ({
	token, data, params 
}) => {
	return backendAxios.post("catechists/", 
		data, 
		{ 
			headers: { Authorization: `Bearer ${token}`, },
			params,
		}
	);
};

export const createMass = ({
	token, data 
}) => {
	return backendAxios.post("masses/", data, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const createMeeting = ({
	token, data 
}) => {
	return backendAxios.post("meetings/", data, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const createCatechesis = ({
	token, data 
}) => {
	return backendAxios.post("catechesis/", data, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const deleteRelationship = ({
	token, id 
}) => {
	return backendAxios.delete(`relationships/${id}/`, { headers: { Authorization: `Bearer ${token}`, }, });
};

export const deleteUser = ({
	token, id 
}) => {
	return backendAxios.delete(`users/${id}/`, { headers: { Authorization: `Bearer ${token}`, }, });
};
